import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import { FaChevronDown } from "react-icons/fa";

const BlogDetail = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [blog, setBlog] = useState(null);
    const [isGiftNiftyOpen, setGiftNiftyOpen] = useState(false);

    const handleGiftNiftyToggle = () => {
        setGiftNiftyOpen(prev => !prev); // Toggle dropdown
    };

    const handleGiftNiftyMouseEnter = () => {
        setGiftNiftyOpen(true); // Open dropdown on hover
    };

    const handleGiftNiftyMouseLeave = () => {
        setGiftNiftyOpen(false); // Close dropdown on mouse leave
    };

    const handleClick = () => {
        navigate("/blogs");
    };

    useEffect(() => {
        fetch(`https://giftnifty.com.in/api/blogs/${id}`)
            .then(response => response.json())
            .then(data => {
                setBlog(data);
            });
    }, [id]);

    return (<div className="desktop" style={{ height: blog ? null : '100vh' }}>
        <div style={{
            height: '90px',
            backgroundColor: '#181A23',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
            display: 'flex',
            width: '100%',
            position: 'sticky',
            top: 0,
            zIndex: 1000
        }}>
            <div style={{ width: '1341px', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between', display: 'flex' }}>
                <img className="logo-white" alt="Logo white" src={require("./assets/logo-white.png")} style={{ cursor: 'pointer' }} onClick={() => {
                    navigate("/");
                }} />
                <div style={{ alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between', display: 'flex' }}>
                    <p onClick={() => {
                        navigate("/blogs");
                    }} style={{ cursor: "pointer", fontFamily: 'Poppins', fontSize: '16px', fontWeight: '600', marginLeft: '20px', marginRight: '20px', color: '#DFE0ED' }}>BLOGS</p>
                    <p onClick={() => {
                        navigate("/news");
                    }} style={{ cursor: "pointer", fontFamily: 'Poppins', fontSize: '16px', fontWeight: '600', marginLeft: '20px', marginRight: '20px', color: '#DFE0ED' }}>NEWS</p>
                    <div onMouseEnter={handleGiftNiftyMouseEnter} onMouseLeave={handleGiftNiftyMouseLeave} style={{ position: 'relative' }}>
                        <p style={{ fontFamily: 'Poppins', fontSize: '16px', fontWeight: '600', color: '#DFE0ED', marginLeft: '20px', marginRight: '20px', cursor: 'pointer', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>GIFT NIFTY <FaChevronDown style={{ marginLeft: '10px' }} /></p>
                        {isGiftNiftyOpen && (
                            <div style={{
                                position: 'absolute',
                                backgroundColor: '#181A23',
                                borderRadius: '5px',
                                padding: '10px',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                                zIndex: 100,
                                width: '300px'
                            }}>
                                <p
  style={{
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: '600',
    color: '#DFE0ED',
    margin: '20px',
    cursor: 'pointer',
  }}
>
  <a
    href="https://insider.giftnifty.com.in/blog/what-is-gift-nifty/"
    target="_blank"
    rel="dofollow"
    style={{ color: '#DFE0ED', textDecoration: 'none' }}
  >
    What is GIFT NIFTY?
  </a>
</p>

<p
  style={{
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: '600',
    color: '#DFE0ED',
    margin: '20px',
    cursor: 'pointer',
  }}
>
  <a
    href="https://insider.giftnifty.com.in/blog/gift-nifty-vs-sgx-nifty/"
    target="_blank"
    rel="dofollow"
    style={{ color: '#DFE0ED', textDecoration: 'none' }}
  >
    GIFT NIFTY VS SGX NIFTY
  </a>
</p>

<p
  style={{
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: '600',
    color: '#DFE0ED',
    margin: '20px',
    cursor: 'pointer',
  }}
>
  <a
    href="https://insider.giftnifty.com.in/blog/gift-nifty-vs-nifty-50/"
    target="_blank"
    rel="dofollow"
    style={{ color: '#DFE0ED', textDecoration: 'none' }}
  >
    GIFT NIFTY VS NIFTY 50
  </a>
</p>

<p
  style={{
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: '600',
    color: '#DFE0ED',
    margin: '20px',
    cursor: 'pointer',
  }}
>
  <a
    href="https://insider.giftnifty.com.in/blog/gift-nifty-timings/"
    target="_blank"
    rel="dofollow"
    style={{ color: '#DFE0ED', textDecoration: 'none' }}
  >
    GIFT NIFTY Timing
  </a>
</p></div>
                        )}
                    </div>
                    <FaSearch onClick={() => {
                        navigate("/search");
                    }} size={20} style={{ color: '#8C52FF', marginLeft: '20px', marginRight: '20px' }} />
                </div>
            </div>
        </div>
        <div style={{
            display: 'flex', flexDirection: 'row',
            color: 'white'
        }}>
            {blog ? <div className="main-axis-container" style={{ padding: '30px' }}>
                <div dangerouslySetInnerHTML={{ __html: blog.content }} /> {/* WYSIWYG Content */}
            </div> : <p style={{
                color: 'white'
            }}>Loading...</p>}

            <div className="second-axis-container">
                <img className="main-axis" alt="Logo white" src={require("./assets/ad.png")} />
            </div>
        </div>
        <div style={{ marginTop: '30px', backgroundColor: '#181A23', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', display: 'flex', width: '100%' }}>
            <div style={{ width: '1341px', marginTop: '30px', alignItems: 'center', flexDirection: 'row', justifyContent: 'center', display: 'flex', }}>
                <img className="logo-white" alt="Logo white" src={require("./assets/logo-white.png")} style={{ cursor: 'pointer' }} onClick={() => {
                    navigate("/");
                }} />
                <div style={{ marginTop: '30px', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', display: 'flex', }} >
                    <div style={{ marginTop: '30px', alignItems: 'center', flexDirection: 'row', justifyContent: 'center', display: 'flex', }} >
                        <p style={{
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontWeight: '600',
                            marginLeft: '20px',
                            marginRight: '20px',
                            color: '#DFE0ED',
                            textTransform: 'uppercase', cursor: "pointer"
                        }} onClick={() => {
                            navigate(`/about`);
                        }} >About</p>
                        <p style={{
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontWeight: '600',
                            marginLeft: '20px',
                            marginRight: '20px',
                            color: '#DFE0ED',
                            textTransform: 'uppercase', cursor: "pointer"
                        }} onClick={() => {
                            navigate(`/contact-us`);
                        }} >Contact Us</p>
                        <p style={{
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontWeight: '600',
                            marginLeft: '20px',
                            marginRight: '20px',
                            color: '#DFE0ED',
                            textTransform: 'uppercase', cursor: "pointer"
                        }} onClick={() => {
                            navigate(`/contact-us`);
                        }} >Terms and Conditions</p>
                        <p style={{
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontWeight: '600',
                            marginLeft: '20px',
                            marginRight: '20px',
                            color: '#DFE0ED',
                            textTransform: 'uppercase', cursor: "pointer"
                        }} onClick={() => {
                            navigate(`/contact-us`);
                        }} >Privacy Policy</p>
                        <p style={{
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontWeight: '600',
                            color: '#DFE0ED',
                            marginLeft: '20px',
                            marginRight: '20px',
                            textTransform: 'uppercase', cursor: "pointer"
                        }} onClick={() => {
                            navigate(`/disclaimer`);
                        }} >Disclaimer</p>
                    </div>
                    <p style={{
                        fontFamily: 'Poppins',
                        fontSize: '12px',
                        color: '#DFE0ED',
                        textAlign: 'start',
                        margin: '20px',
                        lineHeight: 'normal'
                    }}>This website is solely for stock market information purposes and is not affiliated with any official entities such as SGX, NSE, NSEIX, IFSC, GIFT City, or Nifty. It does not act as a financial advisor or influencer, and we do not offer trading or investment advice, tips,
                        recommendations, or strategies through our website, social media channels, or any other
                        medium.<br />All users and members of this website are subject to our Disclaimer, Disclosure, Privacy
                        Policy, and Terms & Conditions. By using this website, you acknowledge and agree to all of the above terms and conditions.
                    </p>
                    <p style={{
                        fontFamily: 'Poppins',
                        fontSize: '16px',
                        fontWeight: '600',
                        color: '#DFE0ED',
                        margin: '20px',
                        cursor: 'pointer'
                    }} onClick={() => {
                        navigate("/");
                    }} >© 2024 GIFTNIFTY</p>
                </div>
            </div>
        </div>
    </div >);
}


export default BlogDetail;