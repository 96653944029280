import './App.css';
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { FaSearch } from "react-icons/fa";
import { FaChevronDown } from "react-icons/fa";

const AboutUs = () => {
    const navigate = useNavigate();
    const [isGiftNiftyOpen, setGiftNiftyOpen] = useState(false);

    const handleGiftNiftyToggle = () => {
        setGiftNiftyOpen(prev => !prev); // Toggle dropdown
    };

    const handleGiftNiftyMouseEnter = () => {
        setGiftNiftyOpen(true); // Open dropdown on hover
    };

    const handleGiftNiftyMouseLeave = () => {
        setGiftNiftyOpen(false); // Close dropdown on mouse leave
    };

    const handleClick = () => {
        navigate("/blogs");
    };

    return (
        <div className="desktop">
            <Helmet>

  <title>About Gift Nifty - Your Source for Live Market Data</title>
  <meta name="description" content="Learn about GIFT NIFTY, your trusted source for live market data, real-time updates, and trading insights. Stay informed to make smarter investment decisions." />
  <meta name="keywords" content="About GIFT Nifty, GIFT Nifty Data, GIFT Nifty Live Market, GIFT Nifty Insights, Live Trading Data" />
  <meta name="author" content="GIFT NIFTY" />
  
  <meta property="og:title" content="About GIFT NIFTY: Your Source for Live Market Data" />
  <meta property="og:description" content="Discover the mission and features of GIFT NIFTY, your go-to platform for live trading data, market trends, and investment insights." />
  <meta property="og:url" content="https://giftnifty.com.in/about" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="./assets/logo-white.png" />
  
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="About GIFT NIFTY: Your Source for Live Market Data" />
  <meta name="twitter:description" content="Learn about GIFT NIFTY, your trusted source for live market data, real-time updates, and trading insights. Stay informed to make smarter investment decisions." />
  <meta name="twitter:image" content="./assets/logo-white.png" />
  
  <link rel="icon" href="%PUBLIC_URL%/favicon.ico" />
  <link rel="apple-touch-icon" href="%PUBLIC_URL%/logo192.png" />
  <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />

  <script async src="https://www.googletagmanager.com/gtag/js?id=G-4RXK10F79N"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag() { dataLayer.push(arguments); }
            gtag('js', new Date());
            gtag('config', 'G-4RXK10F79N');
          `}
        </script>
</Helmet>

            <div style={{
                height: '90px',
                backgroundColor: '#181A23',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'center',
                display: 'flex',
                width: '100%',
                position: 'sticky',
                top: 0,
                zIndex: 1000
            }}>
                <div style={{ width: '1341px', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between', display: 'flex' }}>
                    <img className="logo-white" alt="Logo white" src={require("./assets/logo-white.png")} style={{ cursor: 'pointer' }} onClick={() => {
                        navigate("/");
                    }} />
                    <div style={{ alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between', display: 'flex' }}>
                        <p onClick={() => {
                            navigate("/blogs");
                        }} style={{ cursor: "pointer", fontFamily: 'Poppins', fontSize: '16px', fontWeight: '600', marginLeft: '20px', marginRight: '20px', color: '#DFE0ED' }}>BLOGS</p>
                        <p onClick={() => {
                            navigate("/news");
                        }} style={{ cursor: "pointer", fontFamily: 'Poppins', fontSize: '16px', fontWeight: '600', marginLeft: '20px', marginRight: '20px', color: '#DFE0ED' }}>NEWS</p>
                        <div onMouseEnter={handleGiftNiftyMouseEnter} onMouseLeave={handleGiftNiftyMouseLeave} style={{ position: 'relative' }}>
                            <p style={{ fontFamily: 'Poppins', fontSize: '16px', fontWeight: '600', color: '#DFE0ED', marginLeft: '20px', marginRight: '20px', cursor: 'pointer', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>GIFT NIFTY <FaChevronDown style={{ marginLeft: '10px' }} /></p>
                            {isGiftNiftyOpen && (
                                <div style={{
                                    position: 'absolute',
                                    backgroundColor: '#181A23',
                                    borderRadius: '5px',
                                    padding: '10px',
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                                    zIndex: 100,
                                    width: '300px'
                                }}>
                                    <p
  style={{
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: '600',
    color: '#DFE0ED',
    margin: '20px',
    cursor: 'pointer',
  }}
>
  <a
    href="https://insider.giftnifty.com.in/blog/what-is-gift-nifty/"
    target="_blank"
    rel="dofollow"
    style={{ color: '#DFE0ED', textDecoration: 'none' }}
  >
    What is GIFT NIFTY?
  </a>
</p>

<p
  style={{
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: '600',
    color: '#DFE0ED',
    margin: '20px',
    cursor: 'pointer',
  }}
>
  <a
    href="https://insider.giftnifty.com.in/blog/gift-nifty-vs-sgx-nifty/"
    target="_blank"
    rel="dofollow"
    style={{ color: '#DFE0ED', textDecoration: 'none' }}
  >
    GIFT NIFTY VS SGX NIFTY
  </a>
</p>

<p
  style={{
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: '600',
    color: '#DFE0ED',
    margin: '20px',
    cursor: 'pointer',
  }}
>
  <a
    href="https://insider.giftnifty.com.in/blog/gift-nifty-vs-nifty-50/"
    target="_blank"
    rel="dofollow"
    style={{ color: '#DFE0ED', textDecoration: 'none' }}
  >
    GIFT NIFTY VS NIFTY 50
  </a>
</p>

<p
  style={{
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: '600',
    color: '#DFE0ED',
    margin: '20px',
    cursor: 'pointer',
  }}
>
  <a
    href="https://insider.giftnifty.com.in/blog/gift-nifty-timings/"
    target="_blank"
    rel="dofollow"
    style={{ color: '#DFE0ED', textDecoration: 'none' }}
  >
    GIFT NIFTY Timing
  </a>
</p>
</div>
                            )}
                        </div>
                        <FaSearch onClick={() => {
                            navigate("/search");
                        }} size={20} style={{ color: '#8C52FF', marginLeft: '20px', marginRight: '20px' }} />
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div className="main-axis" style={{ width: '1341px', borderRadius: '20px', flexDirection: 'column', paddingBottom: '30px' }}>
                    <div className="main-axis" style={{ flexDirection: 'column', display: 'flex' }}>
                        <h5 className="content-title">About</h5>
                        <p className="about-text" style={{
                            lineHeight: 'normal', width: '80%', fontSize: '18px', color: 'white'
                        }}>
                            GIFT Nifty is a derivative of the Nifty index, traded on the GIFT City exchange in India. In simpler terms, GIFT Nifty represents a futures contract that can be traded on this platform. While Indian traders deal with Nifty 50 futures in the Indian stock exchanges, international investors can engage with GIFT Nifty in GIFT City.<br /><br />
                            <span className="about-text-bold">How is GIFT Nifty different from Indian Nifty?<br /></span>
                            The main distinction between GIFT Nifty and Indian Nifty is that GIFT Nifty is a futures<br />
                            contract traded in GIFT City, whereas Indian Nifty is traded solely on the National Stock Exchange (NSE) in India. Another notable difference lies in the contract size: the lot size for Indian Nifty is 50, while there are no such requirements for GIFT Nifty.<br />
                            GIFT Nifty benefits from high trading volumes as it operates as an active trading platform in GIFT City, providing a unique trading environment for investors. This platform offers extended trading hours compared to the traditional Indian exchanges, making it an attractive option for traders seeking flexibility.<br /><br />
                            <span className="about-text-bold">How does GIFT Nifty impact the Indian Market?<br /></span>
                            There is a time difference between Indian Nifty and GIFT Nifty, as the GIFT City market<br />
                            opens approximately two and a half hours before the Indian market. GIFT Nifty operates for extended hours, making it valuable for predicting Indian Nifty’s pre-market behavior. Investors can gauge market fluctuations and anticipate how Nifty trading will begin in India. Checking GIFT Nifty each morning gives traders insights into whether the Indian markets might open positively or negatively.<br /><br />
                            <span className="about-text-bold">What are the market timings for GIFT Nifty?<br /></span>
                            Unlike the Indian markets, which are open for about six hours (9:00 AM to 3:30 PM IST), GIFT<br />
                            Nifty operates for nearly 21 hours, providing ample opportunities for traders.<br />
                        </p>
                    </div>
                </div>
            </div>
            <div style={{ marginTop: '30px', backgroundColor: '#181A23', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', display: 'flex', width: '100%' }}>
            <div style={{ width: '1341px', marginTop: '30px', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', display: 'flex', }}>
                <img className="logo-white" alt="Logo white" src={require("./assets/logo-white.png")} style={{ cursor: 'pointer' }} onClick={() => {
                    navigate("/");
                }} />
                <div style={{ marginTop: '30px', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', display: 'flex', }} >
                    <div style={{ marginTop: '30px', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', display: 'flex', }} >
                        <p style={{
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontWeight: '600',
                            marginLeft: '20px',
                            marginRight: '20px',
                            color: '#DFE0ED',
                            textTransform: 'uppercase', cursor: "pointer"
                        }} onClick={() => {
                            navigate(`/about`);
                        }} >About</p>
                        <p style={{
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontWeight: '600',
                            marginLeft: '20px',
                            marginRight: '20px',
                            color: '#DFE0ED',
                            textTransform: 'uppercase', cursor: "pointer"
                        }} onClick={() => {
                            navigate(`/contact-us`);
                        }} >Contact Us</p>
                        <p style={{
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontWeight: '600',
                            marginLeft: '20px',
                            marginRight: '20px',
                            color: '#DFE0ED',
                            textTransform: 'uppercase', cursor: "pointer"
                        }} onClick={() => {
                            navigate(`/terms-and-conditions`);
                        }} >Terms and Conditions</p>
                        <p style={{
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontWeight: '600',
                            marginLeft: '20px',
                            marginRight: '20px',
                            color: '#DFE0ED',
                            textTransform: 'uppercase', cursor: "pointer"
                        }} onClick={() => {
                            navigate(`/privacy-policy`);
                        }} >Privacy Policy</p>
                        <p style={{
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontWeight: '600',
                            color: '#DFE0ED',
                            marginLeft: '20px',
                            marginRight: '20px',
                            textTransform: 'uppercase', cursor: "pointer"
                        }} onClick={() => {
                            navigate(`/disclaimer`);
                        }} >Disclaimer</p>
                    </div>
                    <p style={{
                        fontFamily: 'Poppins',
                        fontSize: '12px',
                        color: '#DFE0ED',
                        textAlign: 'center',
                        margin: '20px',
                        lineHeight: 'normal',
                        paddingLeft: '150px',
                        paddingRight: '150px'
                    }}>This website is solely for stock market information purposes and is not affiliated with any official entities such as SGX, NSE, NSEIX, IFSC, GIFT City, or Nifty. It does not act as a financial advisor or influencer, and we do not offer trading or investment advice, tips,
                        recommendations, or strategies through our website, social media channels, or any other
                        medium.<br /> <br />All users and members of this website are subject to our Disclaimer, Disclosure, Privacy
                        Policy, and Terms & Conditions. By using this website, you acknowledge and agree to all of the above terms and conditions.
                    </p>
                    <p style={{
                        fontFamily: 'Poppins',
                        fontSize: '16px',
                        fontWeight: '600',
                        color: '#DFE0ED',
                        margin: '20px',
                        cursor: 'pointer'
                    }} onClick={() => {
                        navigate("/");
                    }} >© 2024 GIFTNIFTY</p>
                </div>
            </div>
        </div>
        </div>
    );
}

export default AboutUs;