import './App.css';
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaSearch, FaChevronDown } from "react-icons/fa";

const Search = () => {
    const [blogs, setBlogs] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [tags, setTags] = useState([]); // State for storing tags
    const navigate = useNavigate();
    const [isGiftNiftyOpen, setGiftNiftyOpen] = useState(false);

    const handleGiftNiftyToggle = () => {
        setGiftNiftyOpen(prev => !prev);
    };

    const handleGiftNiftyMouseEnter = () => {
        setGiftNiftyOpen(true);
    };

    const handleGiftNiftyMouseLeave = () => {
        setGiftNiftyOpen(false);
    };

    useEffect(() => {
        // Fetch tags
        fetch('https://insider.giftnifty.com.in/wp-json/wp/v2/tags')
            .then(response => response.json())
            .then(tagData => {
                const formattedTags = tagData.map(tag => ({
                    id: tag.id,
                    name: tag.name
                }));
                setTags(formattedTags);
            });

        // Fetch blog posts
        fetch('https://insider.giftnifty.com.in/wp-json/wp/v2/posts')
            .then(response => response.json())
            .then(data => {
                const formattedBlogs = data
                    .map(blog => ({
                        id: blog.id,
                        title: blog.title.rendered,
                        description: blog.excerpt.rendered,
                        thumbnail: blog.uagb_featured_image_src["2048x2048"][0],
                        createdAt: blog.date,
                        tag: blog.tags[0], // First tag ID (we will map this to a tag name)
                        link: blog.link, // Blog link for opening in a new tab
                    }));
                setBlogs(formattedBlogs);
            });
    }, []);

    // Helper function to get tag name by ID
    const getTagName = (tagId) => {
        const tag = tags.find(t => t.id === tagId);
        return tag ? tag.name : 'No Tag';
    };
    const otherBlogs = blogs;

    const openBlogInNewTab = (url) => {
        window.open(url, '_blank', 'noopener,noreferrer'); // Opens the blog in a new tab
    };

    const filteredBlogs = blogs.filter(blog =>
        blog.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        getTagName(blog.tag).toLowerCase().includes(searchTerm.toLowerCase())
    );


    return (
        <div className="desktop" style={{ height: blogs.length > 0 ? null : '100vh' }}>
            {/* Navbar */}
            <div style={{
                height: '90px',
                backgroundColor: '#181A23',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'center',
                display: 'flex',
                width: '100%',
                position: 'sticky',
                top: 0,
                zIndex: 1000
            }}>
                <div style={{ width: '1341px', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between', display: 'flex' }}>
                    <img className="logo-white" alt="Logo white" src={require("./assets/logo-white.png")} style={{ cursor: 'pointer' }} onClick={() => {
                        navigate("/");
                    }} />
                    <div style={{ alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between', display: 'flex' }}>
                        <p onClick={() => {
                            navigate("/blogs");
                        }} style={{ cursor: "pointer", fontFamily: 'Poppins', fontSize: '16px', fontWeight: '600', marginLeft: '20px', marginRight: '20px', color: '#DFE0ED' }}>BLOGS</p>
                        <p onClick={() => {
                            navigate("/news");
                        }} style={{ cursor: "pointer", fontFamily: 'Poppins', fontSize: '16px', fontWeight: '600', marginLeft: '20px', marginRight: '20px', color: '#DFE0ED' }}>NEWS</p>
                        <div onMouseEnter={handleGiftNiftyMouseEnter} onMouseLeave={handleGiftNiftyMouseLeave} style={{ position: 'relative' }}>
                            <p style={{ fontFamily: 'Poppins', fontSize: '16px', fontWeight: '600', color: '#DFE0ED', marginLeft: '20px', marginRight: '20px', cursor: 'pointer', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>GIFT NIFTY <FaChevronDown style={{ marginLeft: '10px' }} /></p>
                            {isGiftNiftyOpen && (
                                <div style={{
                                    position: 'absolute',
                                    backgroundColor: '#181A23',
                                    borderRadius: '5px',
                                    padding: '10px',
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                                    zIndex: 100,
                                    width: '300px'
                                }}>
                                    <p
  style={{
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: '600',
    color: '#DFE0ED',
    margin: '20px',
    cursor: 'pointer',
  }}
>
  <a
    href="https://insider.giftnifty.com.in/blog/what-is-gift-nifty/"
    target="_blank"
    rel="dofollow"
    style={{ color: '#DFE0ED', textDecoration: 'none' }}
  >
    What is GIFT NIFTY?
  </a>
</p>

<p
  style={{
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: '600',
    color: '#DFE0ED',
    margin: '20px',
    cursor: 'pointer',
  }}
>
  <a
    href="https://insider.giftnifty.com.in/blog/gift-nifty-vs-sgx-nifty/"
    target="_blank"
    rel="dofollow"
    style={{ color: '#DFE0ED', textDecoration: 'none' }}
  >
    GIFT NIFTY VS SGX NIFTY
  </a>
</p>

<p
  style={{
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: '600',
    color: '#DFE0ED',
    margin: '20px',
    cursor: 'pointer',
  }}
>
  <a
    href="https://insider.giftnifty.com.in/blog/gift-nifty-vs-nifty-50/"
    target="_blank"
    rel="dofollow"
    style={{ color: '#DFE0ED', textDecoration: 'none' }}
  >
    GIFT NIFTY VS NIFTY 50
  </a>
</p>

<p
  style={{
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: '600',
    color: '#DFE0ED',
    margin: '20px',
    cursor: 'pointer',
  }}
>
  <a
    href="https://insider.giftnifty.com.in/blog/gift-nifty-timings/"
    target="_blank"
    rel="dofollow"
    style={{ color: '#DFE0ED', textDecoration: 'none' }}
  >
    GIFT NIFTY Timing
  </a>
</p></div>
                            )}
                        </div>
                        <FaSearch onClick={() => {
                            navigate("/search");
                        }} size={20} style={{ color: '#8C52FF', marginLeft: '20px', marginRight: '20px' }} />
                    </div>
                </div>
            </div>

            {/* Main Content */}
            <div style={{ display: 'flex', flexDirection: 'row', flex: '1', height: '100%' }}>
                <div className="main-axis" style={{ width: '1341px', borderRadius: '20px', flexDirection: 'column', paddingBottom: '30px' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <input
                            type="text"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            placeholder="Search by title or tag"
                            style={{
                                padding: '10px',
                                width: '300px',
                                borderRadius: '5px',
                                border: '1px solid #8C52FF',
                                fontSize: '16px',
                                fontFamily: 'Poppins',
                                color: '#DFE0ED',
                                backgroundColor: '#181A23',
                                outline: 'none'
                            }}
                        />
                        <FaSearch size={20} style={{ color: '#8C52FF', marginLeft: '10px' }} />
                    </div>

                    <div style={{
                        marginLeft: '34px',
                        marginRight: '34px',
                        marginTop: '20px',
                        gap: '20px'
                    }}>
                        {filteredBlogs.map((blog, index) => (<div className="blog-item-details" key={blog.id} style={{ padding: '30px', cursor: 'pointer', backgroundColor: index % 2 == 0 ? '#181A23' : null }} onClick={() => openBlogInNewTab(blog.link)}>
                            <div className="blog-item-details-internal">
                                <div className="blog-item-category-box">
                                    <p className="blog-item-category">{getTagName(blog.tag)}</p>
                                </div>
                                <p className="blog-item-title">{blog.title}</p>
                                <div className="blog-item-description"
                                    dangerouslySetInnerHTML={{ __html: blog.description }}
                                    style={{ lineHeight: 'normal', letterSpacing: 'normal' }}
                                />
                            </div>
                            <div className="blog-item-date">{new Date(blog.createdAt).toDateString()}</div>
                        </div>))}
                    </div>
                </div>
            </div>

            <div style={{ marginTop: '30px', backgroundColor: '#181A23', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', display: 'flex', width: '100%' }}>
            <div style={{ width: '1341px', marginTop: '30px', alignItems: 'center', flexDirection: 'row', justifyContent: 'center', display: 'flex', }}>
                <img className="logo-white" alt="Logo white" src={require("./assets/logo-white.png")} style={{ cursor: 'pointer' }} onClick={() => {
                    navigate("/");
                }} />
                <div style={{ marginTop: '30px', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', display: 'flex', }} >
                    <div style={{ marginTop: '30px', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', display: 'flex', }} >
                        <p style={{
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontWeight: '600',
                            marginLeft: '20px',
                            marginRight: '20px',
                            color: '#DFE0ED',
                            textTransform: 'uppercase', cursor: "pointer"
                        }} onClick={() => {
                            navigate(`/about`);
                        }} >About</p>
                        <p style={{
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontWeight: '600',
                            marginLeft: '20px',
                            marginRight: '20px',
                            color: '#DFE0ED',
                            textTransform: 'uppercase', cursor: "pointer"
                        }} onClick={() => {
                            navigate(`/contact-us`);
                        }} >Contact Us</p>
                        <p style={{
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontWeight: '600',
                            marginLeft: '20px',
                            marginRight: '20px',
                            color: '#DFE0ED',
                            textTransform: 'uppercase', cursor: "pointer"
                        }} onClick={() => {
                            navigate(`/terms-and-conditions`);
                        }} >Terms and Conditions</p>
                        <p style={{
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontWeight: '600',
                            marginLeft: '20px',
                            marginRight: '20px',
                            color: '#DFE0ED',
                            textTransform: 'uppercase', cursor: "pointer"
                        }} onClick={() => {
                            navigate(`/privacy-policy`);
                        }} >Privacy Policy</p>
                        <p style={{
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontWeight: '600',
                            color: '#DFE0ED',
                            marginLeft: '20px',
                            marginRight: '20px',
                            textTransform: 'uppercase', cursor: "pointer"
                        }} onClick={() => {
                            navigate(`/disclaimer`);
                        }} >Disclaimer</p>
                    </div>
                    <p style={{
                        fontFamily: 'Poppins',
                        fontSize: '12px',
                        color: '#DFE0ED',
                        textAlign: 'center',
                        margin: '20px',
                        lineHeight: 'normal',
                        PaddingRight: '150px',
                        PaddingLeft: '150px'
                    }}>This website is solely for stock market information purposes and is not affiliated with any official entities such as SGX, NSE, NSEIX, IFSC, GIFT City, or Nifty. It does not act as a financial advisor or influencer, and we do not offer trading or investment advice, tips,
                        recommendations, or strategies through our website, social media channels, or any other
                        medium.<br /> <br />All users and members of this website are subject to our Disclaimer, Disclosure, Privacy
                        Policy, and Terms & Conditions. By using this website, you acknowledge and agree to all of the above terms and conditions.
                    </p>
                    <p style={{
                        fontFamily: 'Poppins',
                        fontSize: '16px',
                        fontWeight: '600',
                        color: '#DFE0ED',
                        margin: '20px',
                        cursor: 'pointer'
                    }} onClick={() => {
                        navigate("/");
                    }} >© 2024 GIFTNIFTY</p>
                </div>
            </div>
        </div>
        </div >
    );
}

export default Search;