import React, { useState } from 'react';
import {
    Container,
    Typography,
    TextField,
    Button,
    Box,
    Snackbar,
    Alert,
    CircularProgress,
} from '@mui/material';

const ChangeAccessToken = () => {
    const [accessToken, setAccessToken] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [snackOpen, setSnackOpen] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true); // Disable button during submission

        try {
            const response = await fetch(`https://giftnifty.com.in/generate-token`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    requestToken: accessToken,
                }),
            });

            const data = await response.json();
            if (response.ok) {
                setMessage(data.message);
                setError('');
                setAccessToken('');
            } else {
                setError(data.error);
                setMessage('');
            }
        } catch (error) {
            setError('Failed to change access token');
            setMessage('');
        } finally {
            setIsSubmitting(false);
            setSnackOpen(true);
        }
    };

    const handleCloseSnackbar = () => {
        setSnackOpen(false);
    };

    return (
        <Container maxWidth="sm">
            <Box sx={{ mt: 4, mb: 4 }}>
                <Typography variant="h4" align="center" gutterBottom>
                    Modify Access Token
                </Typography>
                <Typography variant="subtitle1" align="center" gutterBottom>
                    Make sure you know what you are doing; this is highly risky and can even crash the website.
                </Typography>

                <form onSubmit={handleSubmit}>
                    <TextField
                        label="Request Code"
                        variant="outlined"
                        fullWidth
                        value={accessToken}
                        onChange={(e) => setAccessToken(e.target.value)}
                        required
                        sx={{ mb: 2 }}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        fullWidth
                        disabled={isSubmitting}
                        sx={{ mb: 2 }}
                    >
                        {isSubmitting ? <CircularProgress size={24} /> : 'Submit'}
                    </Button>
                </form>
            </Box>

            <Snackbar open={snackOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                {message ? (
                    <Alert onClose={handleCloseSnackbar} severity="success">
                        {message}
                    </Alert>
                ) : (
                    error && (
                        <Alert onClose={handleCloseSnackbar} severity="error">
                            {error}
                        </Alert>
                    )
                )}
            </Snackbar>
        </Container>
    );
};

export default ChangeAccessToken;