import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

const NewsDetail = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [blog, setBlog] = useState(null);

    useEffect(() => {
        fetch(`https://giftnifty.com.in/api/news/${id}`)
            .then(response => response.json())
            .then(data => {
                setBlog(data);
            });
    }, [id]);

    return (<div className="desktop" style={{ height: blog ? null : '100vh' }}>
        <img className="logo-white" alt="Logo white" src={require("./assets/logo-white.png")} style={{ cursor: 'pointer' }} onClick={() => {
            navigate("/");
        }} />
        <div style={{
            display: 'flex', flexDirection: 'row',
            color: 'white'
        }}>
            {blog ? <div className="main-axis-container" style={{ padding: '30px' }}>
                <div dangerouslySetInnerHTML={{ __html: blog.content }} /> {/* WYSIWYG Content */}
            </div> : <p style={{
                color: 'white'
            }}>Loading...</p>}

            <div className="second-axis-container">
                <img className="main-axis" alt="Logo white" src={require("./assets/ad.png")} />
            </div>
        </div>
    </div >);
}


export default NewsDetail;