import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomeScreen from "./HomeScreen";
import AllBlogs from "./AllBlogs";
import BlogDetail from "./BlogDetail";
import NewsDetail from "./NewsDetail";
import AdminPanel from "./AdminPanel";
import Home from "./admin/Home";
import Login from "./admin/Login";
import AboutUs from "./AboutUs";
import ContactUs from "./ContactUs";
import Disclaimer from "./Disclaimer";
import AllNews from "./AllNews";
import GiftNifty from "./GiftNifty";
import Search from "./Search";
import HomeScreenMobile from "./mobile/HomeScreenMobile";
import AboutUsMobile from "./mobile/AboutUsMobile";
import GiftNiftyMobile from "./mobile/GiftNiftyMobile";
import DisclaimerMobile from "./mobile/DisclaimerMobile";
import ContactUsMobile from "./mobile/ContactUsMobile";
import AllBlogsMobile from "./mobile/AllBlogsMobile";
import AllNewsMobile from "./mobile/AllNewsMobile";
import SearchMobile from "./mobile/SearchMobile";
import TermsConditons from "./TermsConditons";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsConditonsMobile from "./mobile/TermsConditonsMobile";
import PrivacyPolicyMobile from "./mobile/PrivacyPolicyMobile";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768); // Detect initial screen size

  useEffect(() => {
    const loggedInStatus = localStorage.getItem('isLoggedInAdmin');
    if (loggedInStatus === 'true') {
      setIsLoggedIn(true);
    }

    // Update isMobile on window resize
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleLogin = () => {
    localStorage.setItem('isLoggedInAdmin', 'true');
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    localStorage.removeItem('isLoggedInAdmin');
    setIsLoggedIn(false);
  };

  return (
    <Router>
      {isMobile ? <Routes>
        <Route path="/" element={<HomeScreenMobile />} />
        <Route path="/mobile/" element={<HomeScreenMobile />} />
        <Route path="/mobile/about" element={<AboutUsMobile />} />
        <Route path="/mobile/giftnifty" element={<GiftNiftyMobile />} />
        <Route path="/mobile/disclaimer" element={<DisclaimerMobile />} />
        <Route path="/mobile/terms-and-conditions" element={<TermsConditonsMobile />} />
        <Route path="/mobile/privacy-policy" element={<PrivacyPolicyMobile />} />
        <Route path="/mobile/contact-us" element={<ContactUsMobile />} />
        <Route path="/mobile/blogs" element={<AllBlogsMobile />} />
        <Route path="/mobile/news" element={<AllNewsMobile />} />
        <Route path="/mobile/search" element={<SearchMobile />} />
        <Route path="/admin" element={isLoggedIn ? <Home onLogout={handleLogout} /> : <Login onLogin={handleLogin} />} />
      </Routes> : <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/terms-and-conditions" element={<TermsConditons />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/giftnifty" element={<GiftNifty />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/blogs" element={<AllBlogs />} />
        <Route path="/news" element={<AllNews />} />
        <Route path="/search" element={<Search />} />
        <Route path="/blog/:id" element={<BlogDetail />} />
        <Route path="/news/:id" element={<NewsDetail />} />
        <Route path="/admin" element={isLoggedIn ? <Home onLogout={handleLogout} /> : <Login onLogin={handleLogin} />} />
      </Routes>}
    </Router >
  );

  // return (<p>Server is upgrading, kindly check back later.</p>);
}

export default App;
