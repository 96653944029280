import React, { useState } from 'react';
import { Box, Typography, Container, CssBaseline, Paper, Avatar, Button, TextField, CircularProgress } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

const Login = ({ onLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailValid, setEmailValid] = useState(false);
  const [passwordValid, setPasswordValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setEmail(emailValue);
    setEmailValid(validateEmail(emailValue));
  };

  const handlePasswordChange = (e) => {
    const passwordValue = e.target.value;
    setPassword(passwordValue);
    setPasswordValid(passwordValue.length >= 9); // Ensure minimum length
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (emailValid && passwordValid) {
      setIsLoading(true);
      console.log("Form submitted:", { email, password });
      try {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
          "username": email,
          "password": password
        });

        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow"
        };

        const response = await fetch("https://giftnifty.com.in/admin/login-user", requestOptions);
        if (response.ok) {
          const data = await response.json();
          const { token, user } = data;
          localStorage.setItem('isLoggedInAdmin', 'true');
          localStorage.setItem('user', 'false');
          localStorage.setItem('token', token);
          localStorage.setItem('userId', user._id);
          window.location.reload();
        } else {
          localStorage.setItem('loggedIn', 'false');
          alert("Incorrect username or password. Please try again.");
        }
      } catch (error) {
        console.error('Error during login:', error);
        localStorage.setItem('loggedIn', 'false');
        alert("An error occurred during login. Please try again later.");
      } finally {
        setIsLoading(false);
      }
    } else {
      alert("Please fill in all fields correctly.");
    }
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  return (
    <Container component="main" maxWidth="xs" sx={{ height: '92vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <CssBaseline />
      <Paper elevation={6} sx={{ padding: 4, borderRadius: 2, width: '100%' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5" style={{ fontFamily: "Noto Sans" }}>
            GiftNifty Admin Login
          </Typography>
          <Box component="form" sx={{ mt: 1 }} onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Email Address"
              type="email"
              value={email}
              onChange={handleEmailChange}
              error={email !== '' && !emailValid}
              helperText={email !== '' && !emailValid ? 'Please enter a valid email address.' : ''}
              sx={{ borderRadius: '20px' }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Password"
              type="password"
              value={password}
              onChange={handlePasswordChange}
              error={password !== '' && !passwordValid}
              helperText={password !== '' && !passwordValid ? 'Password must be at least 9 characters long.' : ''}
              sx={{ borderRadius: '20px' }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2, borderRadius: '20px' }}
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Login'}
            </Button>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
};

export default Login;