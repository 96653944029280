import React, { useState } from 'react';
import {
  AppBar, Toolbar, Drawer, List, ListItem, ListItemIcon, ListItemText,
  IconButton, CssBaseline, Box, Typography, Button, Container, TextField, Dialog, DialogActions, DialogContent, DialogTitle
} from '@mui/material';
import { Menu, Dashboard, VpnKey, Logout } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ChangePassword from './ChangePassword';
import ChangeAccessToken from './ChangeAccessToken';

const drawerWidth = 240;

const Home = ({ onLogout }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [mobileOpen, setMobileOpen] = useState(false);
  const [screen, setScreen] = useState(0);
  const [blogs, setBlogs] = useState([{ id: 1, title: 'First Blog', content: 'Content of first blog' }]);
  const [news, setNews] = useState([{ id: 1, title: 'First News', content: 'Content of first news' }]);

  const [openForm, setOpenForm] = useState(false);
  const [formData, setFormData] = useState({ title: '', content: '' });
  const [isEditing, setIsEditing] = useState(false);
  const [editingId, setEditingId] = useState(null);

  const handleDrawerToggle = () => setMobileOpen(!mobileOpen);
  const handleOpenForm = (isEdit, data) => {
    setIsEditing(isEdit);
    setFormData(isEdit ? { ...data } : { title: '', content: '' });
    if (isEdit) setEditingId(data.id);
    setOpenForm(true);
  };

  const handleCloseForm = () => {
    setFormData({ title: '', content: '' });
    setIsEditing(false);
    setOpenForm(false);
  };

  const handleFormSubmit = () => {
    const updatedData = isEditing
      ? (screen === 1 ? blogs : news).map(item => (item.id === editingId ? { ...formData, id: editingId } : item))
      : [...(screen === 1 ? blogs : news), { ...formData, id: new Date().getTime() }];

    if (screen === 1) setBlogs(updatedData);
    if (screen === 2) setNews(updatedData);

    handleCloseForm();
  };

  const handleDelete = (id, type) => {
    if (type === 'blog') setBlogs(blogs.filter(blog => blog.id !== id));
    if (type === 'news') setNews(news.filter(item => item.id !== id));
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ zIndex: theme.zIndex.drawer + 1 }}>
        <Toolbar>
          {isMobile && (
            <IconButton color="inherit" edge="start" onClick={handleDrawerToggle} sx={{ mr: 2 }}>
              <Menu />
            </IconButton>
          )}
          <Typography variant="h6" noWrap component="div">
            Admin Dashboard
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant={isMobile ? 'temporary' : 'permanent'}
        open={mobileOpen}
        onClose={handleDrawerToggle}
        sx={{ width: drawerWidth, flexShrink: 0, '& .MuiDrawer-paper': { width: drawerWidth, boxSizing: 'border-box' } }}
        ModalProps={{ keepMounted: true }}
      >
        <Toolbar />
        <List>
          <ListItem button onClick={() => setScreen(0)}>
            <ListItemIcon><Dashboard /></ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
          <ListItem button onClick={() => setScreen(1)}>
            <ListItemIcon><VpnKey /></ListItemIcon>
            <ListItemText primary="Access Token" />
          </ListItem>
          <ListItem button onClick={() => setScreen(2)}>
            <ListItemIcon><VpnKey /></ListItemIcon>
            <ListItemText primary="Change Password" />
          </ListItem>
          <ListItem button onClick={onLogout}>
            <ListItemIcon><Logout /></ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}>
        <Toolbar />
        <Container component="main">
          {screen === 1 && <ChangeAccessToken />}
          {screen === 2 && <ChangePassword />}
        </Container>
      </Box>

      {/* Create/Edit Form Dialog */}
      <Dialog open={openForm} onClose={handleCloseForm}>
        <DialogTitle>{isEditing ? 'Edit' : 'Create'} {screen === 1 ? 'Blog' : 'News'}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Title"
            type="text"
            fullWidth
            variant="outlined"
            value={formData.title}
            onChange={(e) => setFormData({ ...formData, title: e.target.value })}
          />
          <TextField
            margin="dense"
            label="Content"
            type="text"
            fullWidth
            variant="outlined"
            multiline
            rows={4}
            value={formData.content}
            onChange={(e) => setFormData({ ...formData, content: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseForm} color="secondary">Cancel</Button>
          <Button onClick={handleFormSubmit} color="primary">{isEditing ? 'Update' : 'Create'}</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Home;