import './App.css';
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet"; // Import Helmet for managing document head
import { FaSearch } from "react-icons/fa";
import { FaChevronDown } from "react-icons/fa";

const TermsConditons = () => {
    const navigate = useNavigate();
    const [isGiftNiftyOpen, setGiftNiftyOpen] = useState(false);

    const handleGiftNiftyToggle = () => {
        setGiftNiftyOpen(prev => !prev); // Toggle dropdown
    };

    const handleGiftNiftyMouseEnter = () => {
        setGiftNiftyOpen(true); // Open dropdown on hover
    };

    const handleGiftNiftyMouseLeave = () => {
        setGiftNiftyOpen(false); // Close dropdown on mouse leave
    };

    const handleClick = () => {
        navigate("/blogs");
    };

    return (
        <div className="desktop">
            <Helmet>
                <title>GiftNifty.com.in Terms & Conditions</title>
                <meta name="description" content="Read the Terms & Conditions for using GiftNifty.com.in. Stay informed about the policies and guidelines for accessing live GIFT Nifty prices and updates for traders in India." />
                <meta name="keywords" content="GIFT Nifty Terms, GIFT Nifty Conditions, GIFT Nifty Policies, GIFT Nifty Trading Terms" />
                <meta name="author" content="Gift Nifty" />

                {/* Open Graph / Facebook Meta Tags */}
                <meta property="og:title" content="GiftNifty.com.in Terms & Conditions" />
                <meta property="og:description" content="Explore the Terms & Conditions for using GiftNifty.com.in, your go-to platform for live GIFT Nifty prices and trading updates." />
                <meta property="og:url" content="https://giftnifty.com.in/terms-and-conditions" />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="./assets/logo-white.png" />

                {/* Twitter Meta Tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="GiftNifty.com.in Terms & Conditions" />
                <meta name="twitter:description" content="Explore the Terms & Conditions for using GiftNifty.com.in, your go-to platform for live GIFT Nifty prices and trading updates." />
                <meta name="twitter:image" content="./assets/logo-white.png" />
                <link rel="apple-touch-icon" href="%PUBLIC_URL%/logo192.png" />
                <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />
            </Helmet>
            <div style={{
                height: '90px',
                backgroundColor: '#181A23',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'center',
                display: 'flex',
                width: '100%',
                position: 'sticky',
                top: 0,
                zIndex: 1000
            }}>
                <div style={{ width: '1341px', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between', display: 'flex' }}>
                    <img className="logo-white" alt="Logo white" src={require("./assets/logo-white.png")} style={{ cursor: 'pointer' }} onClick={() => {
                        navigate("/");
                    }} />
                    <div style={{ alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between', display: 'flex' }}>
                        <p onClick={() => {
                            navigate("/blogs");
                        }} style={{ cursor: "pointer", fontFamily: 'Poppins', fontSize: '16px', fontWeight: '600', marginLeft: '20px', marginRight: '20px', color: '#DFE0ED' }}>BLOGS</p>
                        <p onClick={() => {
                            navigate("/news");
                        }} style={{ cursor: "pointer", fontFamily: 'Poppins', fontSize: '16px', fontWeight: '600', marginLeft: '20px', marginRight: '20px', color: '#DFE0ED' }}>NEWS</p>
                        <div onMouseEnter={handleGiftNiftyMouseEnter} onMouseLeave={handleGiftNiftyMouseLeave} style={{ position: 'relative' }}>
                            <p style={{ fontFamily: 'Poppins', fontSize: '16px', fontWeight: '600', color: '#DFE0ED', marginLeft: '20px', marginRight: '20px', cursor: 'pointer', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>GIFT NIFTY <FaChevronDown style={{ marginLeft: '10px' }} /></p>
                            {isGiftNiftyOpen && (
                                <div style={{
                                    position: 'absolute',
                                    backgroundColor: '#181A23',
                                    borderRadius: '5px',
                                    padding: '10px',
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                                    zIndex: 100,
                                    width: '300px'
                                }}>
                                    <p
                                        style={{
                                            fontFamily: 'Poppins',
                                            fontSize: '16px',
                                            fontWeight: '600',
                                            color: '#DFE0ED',
                                            margin: '20px',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <a
                                            href="https://insider.giftnifty.com.in/blog/what-is-gift-nifty/"
                                            target="_blank"
                                            rel="dofollow"
                                            style={{ color: '#DFE0ED', textDecoration: 'none' }}
                                        >
                                            What is GIFT NIFTY?
                                        </a>
                                    </p>

                                    <p
                                        style={{
                                            fontFamily: 'Poppins',
                                            fontSize: '16px',
                                            fontWeight: '600',
                                            color: '#DFE0ED',
                                            margin: '20px',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <a
                                            href="https://insider.giftnifty.com.in/blog/gift-nifty-vs-sgx-nifty/"
                                            target="_blank"
                                            rel="dofollow"
                                            style={{ color: '#DFE0ED', textDecoration: 'none' }}
                                        >
                                            GIFT NIFTY VS SGX NIFTY
                                        </a>
                                    </p>

                                    <p
                                        style={{
                                            fontFamily: 'Poppins',
                                            fontSize: '16px',
                                            fontWeight: '600',
                                            color: '#DFE0ED',
                                            margin: '20px',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <a
                                            href="https://insider.giftnifty.com.in/blog/gift-nifty-vs-nifty-50/"
                                            target="_blank"
                                            rel="dofollow"
                                            style={{ color: '#DFE0ED', textDecoration: 'none' }}
                                        >
                                            GIFT NIFTY VS NIFTY 50
                                        </a>
                                    </p>

                                    <p
                                        style={{
                                            fontFamily: 'Poppins',
                                            fontSize: '16px',
                                            fontWeight: '600',
                                            color: '#DFE0ED',
                                            margin: '20px',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <a
                                            href="https://insider.giftnifty.com.in/blog/gift-nifty-timings/"
                                            target="_blank"
                                            rel="dofollow"
                                            style={{ color: '#DFE0ED', textDecoration: 'none' }}
                                        >
                                            GIFT NIFTY Timing
                                        </a>
                                    </p>
                                </div>
                            )}
                        </div>
                        <FaSearch onClick={() => {
                            navigate("/search");
                        }} size={20} style={{ color: '#8C52FF', marginLeft: '20px', marginRight: '20px' }} />
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div className="main-axis" style={{ width: '1341px', borderRadius: '20px', flexDirection: 'column', paddingBottom: '30px' }}>
                    <div className="main-axis" style={{ flexDirection: 'column', display: 'flex' }}>
                        <p className="about-text" style={{
                            lineHeight: 'normal', width: '80%', fontSize: '18px', color: 'white'
                        }}>
                            <span className="about-text-bold">Terms of Service</span><br />
                            <br />PLEASE READ THE TERMS OF USE CAREFULLY BEFORE YOU START TO USE THE GIFT NIFTY
                            SERVICES. YOUR AFFIRMATIVE ACT OF USING THE GIFT NIFTY SERVICES SIGNIFIES THAT YOU AGREE TO THE FOLLOWING TERMS OF USE, AND YOU CONSENT TO THE COLLECTION, USE, AND DISCLOSURE OF PERSONAL INFORMATION AS DISCLOSED IN THE PRIVACY POLICY, WHICH IS INCORPORATED HEREIN BY REFERENCE. IF YOU DO NOT AGREE, DO NOT USE THE GIFT NIFTY SERVICES.
                            <br />These Terms of Use govern your use of any services provided by GIFT NIFTY (“https://giftnifty.com.in”, “we”, or “us”), including without limitation, any content, functionality, or services offered on or through GIFT NIFTY (collectively, the “GIFT NIFTY Services”), whether as a guest or a registered user.
                            <br /><br /><br /><span className="about-text-bold">Change In Service and Terms</span>
                            <br />GIFT NIFTY may modify or terminate the GIFT NIFTY Services from time to time, for any reason, and without notice.
                            <br />GIFT NIFTY reserves the right to modify these terms of service from time to time without notice.
                            <br />Any such change, update, or modification to these terms of service will be effective immediately upon posting on the Site.
                            <br />You are responsible for checking these terms periodically for changes. If you continue to use the GIFT NIFTY Services after we post changes to these Terms of Use, you are signifying your acceptance of the new terms.
                            <br /><br /><span className="about-text-bold">Data Content</span>
                            <br />The data contained in GIFT NIFTY’s search results, or linked from those results, have been
                            created by individuals over whom GIFT NIFTY exercises no control.
                            <br />GIFT NIFTY’s data search results are indexed in an automated manner, and GIFT NIFTY does
                            not screen the data. GIFT NIFTY assumes no responsibility for the content of any data listing or website that is either included in GIFT NIFTY’s search results or linked to by giftnifty.com.in.
                            <br /><br /><span className="about-text-bold">Forums</span>
                            <br />The GIFT NIFTY message boards, other forums, as well as other opportunities to post
                            hosted by GIFT NIFTY and its designees (collectively, the “Forums”) are designed to help readers obtain career and company information and discuss ways to make career decisions. This information is provided by outside contributors, many of whom use anonymous screen names.
                            <br />They may occasionally post messages or make statements that are misleading, deceptive, or outright incorrect.
                            <br />GIFT NIFTY does not endorse and is not responsible for any opinion, advice, information, or statement made or displayed on the Forums by third parties.
                            <br />Users of the Forum must not post content or information that is unlawful, fraudulent, threatening, abusive, libelous, defamatory, obscene, or otherwise objectionable or that contains sexual, ethnic, racial, or other discriminatory slurs, or material which contains no relevant or constructive content or which goes beyond profanity into obscenity or abhorrence.
                            <br />Users are also prohibited from posting any proprietary information, trade secrets, or confidential information. Users may not post any advertisements, solicitations, chain letters, pyramid schemes, investment opportunities, or other unsolicited commercial

                            communications (except as otherwise expressly permitted by us), or engage in
                            spamming or flooding.
                            <br />Although GIFT NIFTY has no obligation to do so, GIFT NIFTY may monitor messages posted
                            on its Forums and reserves the right to delete portions of or entire posts that violate the above rules, messages or topics that are unrelated to the specific message board on which they are posted, and advertisements, recruiting, or other commercial messages.
                            <br />If you believe a message violates our policies, please contact GIFT NIFTY immediately so that we can consider its editing or removal.
                            <br />GIFT NIFTY reserves the right to disclose such materials and the circumstances surrounding their transmission to any third party in order to operate its site properly; to protect itself, its partners, and its visitors; and to comply with legal obligations or governmental requests.
                            <br /><br /><span className="about-text-bold">Ownership and Rights to Use Materials</span>
                            <br />GIFT NIFTY does not claim ownership of information, data, text, or other materials
                            submitted by third parties (collectively, “Materials”).
                            <br />However, by posting Materials to any area of the GIFT NIFTY website, you grant GIFT NIFTY
                            and its designees a worldwide, royalty-free, non-exclusive, perpetual, irrevocable, and fully sub-licensable license to use, distribute, reproduce, modify, adapt, publish, translate, publicly perform, and publicly display such Materials (in whole or in part) and to incorporate such Materials into other works in any format or medium now known or later developed.
                            <br />We shall not be liable for any use or disclosure of any Materials.
                            <br /><br /><span className="about-text-bold">Registration</span>
                            <br />Some areas of the GIFT NIFTY service require you to register as a member.
                            <br />If you register to become a member, you agree to (a) provide true, accurate, current, and
                            complete information about yourself as prompted by our registration form (including your email address) and (b) be responsible for the confidentiality and use of your username and password and agree not to transfer or resell your use of or access to the Site to any third party.
                            <br />If you require any more information or have any questions about our terms of service, please feel free to contact us.
                            <br />This Terms of Service was last modified on October 30, 2022.
                        </p>
                    </div>
                </div>
            </div>
            <div style={{ marginTop: '30px', backgroundColor: '#181A23', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', display: 'flex', width: '100%' }}>
                <div style={{ width: '1341px', marginTop: '30px', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', display: 'flex', }}>
                    <img className="logo-white" alt="Logo white" src={require("./assets/logo-white.png")} style={{ cursor: 'pointer' }} onClick={() => {
                        navigate("/");
                    }} />
                    <div style={{ marginTop: '30px', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', display: 'flex', }} >
                        <div style={{ marginTop: '30px', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', display: 'flex', }} >
                            <p style={{
                                fontFamily: 'Poppins',
                                fontSize: '16px',
                                fontWeight: '600',
                                marginLeft: '20px',
                                marginRight: '20px',
                                color: '#DFE0ED',
                                textTransform: 'uppercase', cursor: "pointer"
                            }} onClick={() => {
                                navigate(`/about`);
                            }} >About</p>
                            <p style={{
                                fontFamily: 'Poppins',
                                fontSize: '16px',
                                fontWeight: '600',
                                marginLeft: '20px',
                                marginRight: '20px',
                                color: '#DFE0ED',
                                textTransform: 'uppercase', cursor: "pointer"
                            }} onClick={() => {
                                navigate(`/contact-us`);
                            }} >Contact Us</p>
                            <p style={{
                                fontFamily: 'Poppins',
                                fontSize: '16px',
                                fontWeight: '600',
                                marginLeft: '20px',
                                marginRight: '20px',
                                color: '#DFE0ED',
                                textTransform: 'uppercase', cursor: "pointer"
                            }} onClick={() => {
                                navigate(`/terms-and-conditions`);
                            }} >Terms and Conditions</p>
                            <p style={{
                                fontFamily: 'Poppins',
                                fontSize: '16px',
                                fontWeight: '600',
                                marginLeft: '20px',
                                marginRight: '20px',
                                color: '#DFE0ED',
                                textTransform: 'uppercase', cursor: "pointer"
                            }} onClick={() => {
                                navigate(`/privacy-policy`);
                            }} >Privacy Policy</p>
                            <p style={{
                                fontFamily: 'Poppins',
                                fontSize: '16px',
                                fontWeight: '600',
                                color: '#DFE0ED',
                                marginLeft: '20px',
                                marginRight: '20px',
                                textTransform: 'uppercase', cursor: "pointer"
                            }} onClick={() => {
                                navigate(`/disclaimer`);
                            }} >Disclaimer</p>
                        </div>
                        <p style={{
                            fontFamily: 'Poppins',
                            fontSize: '12px',
                            color: '#DFE0ED',
                            textAlign: 'center',
                            margin: '20px',
                            lineHeight: 'normal',
                            paddingLeft: '150px',
                            paddingRight: '150px'
                        }}>This website is solely for stock market information purposes and is not affiliated with any official entities such as SGX, NSE, NSEIX, IFSC, GIFT City, or Nifty. It does not act as a financial advisor or influencer, and we do not offer trading or investment advice, tips,
                            recommendations, or strategies through our website, social media channels, or any other
                            medium.<br /> <br />All users and members of this website are subject to our Disclaimer, Disclosure, Privacy
                            Policy, and Terms & Conditions. By using this website, you acknowledge and agree to all of the above terms and conditions.
                        </p>
                        <p style={{
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontWeight: '600',
                            color: '#DFE0ED',
                            margin: '20px',
                            cursor: 'pointer'
                        }} onClick={() => {
                            navigate("/");
                        }} >© 2024 GIFTNIFTY</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TermsConditons;