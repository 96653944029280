import '../App.css';
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet"; // Import Helmet for managing document head
import { FaSearch } from "react-icons/fa";
import { FaChevronDown } from "react-icons/fa";

const ContactUsMobile = () => {
    const navigate = useNavigate();
    const [containerWidth, setContainerWidth] = useState(window.innerWidth - 40);
    const [isGiftNiftyOpen, setGiftNiftyOpen] = useState(false);

    useEffect(() => {
        // Update width on window resize
        const handleResize = () => {
            setContainerWidth(window.innerWidth - 40); // Adjust for padding
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleGiftNiftyToggle = () => {
        setGiftNiftyOpen(prev => !prev); // Toggle dropdown
    };

    const handleGiftNiftyMouseEnter = () => {
        setGiftNiftyOpen(true); // Open dropdown on hover
    };

    const handleGiftNiftyMouseLeave = () => {
        setGiftNiftyOpen(false); // Close dropdown on mouse leave
    };

    const handleClick = () => {
        navigate("/all-blogs");
    };

    return (
        <div className="desktop">
            <div
                style={{
                    height: '90px',
                    backgroundColor: '#181A23',
                    display: 'flex',
                    flexDirection: 'column', // Stack items vertically
                    alignItems: 'center', // Center items horizontally
                    justifyContent: 'center', // Center items vertically
                    width: '100%',
                    position: 'sticky',
                    top: 0,
                    zIndex: 1000,
                    padding: '10px 20px', // Add padding for left/right
                }}
            >
                <img
                    className="logo-white"
                    alt="Logo white"
                    src={require("../assets/logo-white.png")}
                    style={{ cursor: 'pointer', width: '150px', height: 'auto' }} // Adjust logo size
                    onClick={() => {
                        navigate("/mobile");
                    }}
                />
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-around', // Space out navigation items
                        flexWrap: 'wrap', // Allow wrapping
                        gap: '15px', // Use gap for spacing between elements
                        marginTop: '10px', // Add some margin above the nav items
                        width: '100%', // Full width for nav items
                    }}
                >
                    <p
                        onClick={() => {
                            navigate("/mobile/blogs");
                        }}
                        style={{
                            cursor: "pointer",
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontWeight: '600',
                            color: '#DFE0ED',
                            margin: '0', // Remove margin
                        }}
                    >
                        BLOGS
                    </p>
                    <p
                        onClick={() => {
                            navigate("/mobile/news");
                        }}
                        style={{
                            cursor: "pointer",
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontWeight: '600',
                            color: '#DFE0ED',
                            margin: '0', // Remove margin
                        }}
                    >
                        NEWS
                    </p>
                    <div
                        onMouseEnter={handleGiftNiftyMouseEnter}
                        onMouseLeave={handleGiftNiftyMouseLeave}
                        style={{ position: 'relative' }}
                    >
                        <p
                            style={{
                                fontFamily: 'Poppins',
                                fontSize: '16px',
                                fontWeight: '600',
                                color: '#DFE0ED',
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                margin: '0', // Remove margin
                            }}
                        >
                            GIFT NIFTY <FaChevronDown style={{ marginLeft: '10px' }} />
                        </p>
                        {isGiftNiftyOpen && (
                            <div
                                style={{
                                    position: 'absolute',
                                    backgroundColor: '#181A23',
                                    borderRadius: '5px',
                                    padding: '10px',
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                                    zIndex: 100,
                                    width: '200px', // Adjust width for mobile
                                }}
                            >
                                {[
                                    { text: "What is GIFT NIFTY?", link: "https://insider.giftnifty.com.in/blog/what-is-gift-nifty/" },
                                    { text: "GIFT NIFTY VS SGX NIFTY", link: "https://insider.giftnifty.com.in/blog/gift-nifty-vs-sgx-nifty/" },
                                    { text: "GIFT NIFTY VS NIFTY 50", link: "https://insider.giftnifty.com.in/blog/gift-nifty-vs-nifty-50/" },
                                    { text: "GIFT NIFTY Timing", link: "https://insider.giftnifty.com.in/blog/gift-nifty-timings/" },
                                ].map((item, index) => (
                                    <a
                                        key={index}
                                        href={item.link}
                                        target="_blank"
                                        style={{
                                            fontFamily: 'Poppins',
                                            fontSize: '16px',
                                            fontWeight: '600',
                                            color: '#DFE0ED',
                                            margin: '10px 0', // Adjust margin for mobile
                                            cursor: 'pointer',
                                            display: 'block', // Ensure each link is displayed in a block
                                        }}
                                    >
                                        {item.text}
                                    </a>
                                ))}
                            </div>
                        )}
                    </div>
                    <FaSearch
                        onClick={() => {
                            navigate("/mobile/search");
                        }}
                        size={20}
                        style={{ color: '#8C52FF' }} // Removed margin for icon
                    />
                </div>
            </div>
            <div style={{
                display: 'flex', flexDirection: 'column',
            }}>
                <div className="main-axis" style={{
                    display: 'flex',
                    width: `${containerWidth}px`, // Set the width based on the state
                    flexDirection: 'column',
                    flexWrap: 'nowrap',
                    justifyContent: 'space-between', // Space out items evenly
                }}>
                    <h5 className="content-title" style={{
                        marginLeft: '0px',
                        marginRight: '0px',
                    }}>Contact Us</h5>
                    <p className="about-text" style={{
                        marginLeft: '0px',
                        marginRight: '0px',
                        lineHeight: 'normal', width: '100%', fontSize: '18px', color: 'white'
                    }}>
                        At GIFTNifty.com.in, we highly value your feedback and are always ready to assist with any queries or concerns you might have. Whether you need help navigating our platform, understanding live GIFT Nifty data, or just general inquiries, our dedicated team is here to support you.<br /><br />
                        <span className="about-text-bold">Get in Touch</span><br /><br />
                        For general inquiries, feedback, or support, feel free to contact us at: <span className="about-text-bold">contact@giftnifty.com.in.</span>
                        <br />
                        <br />
                        <span className="about-text-bold">Why Contact Us?</span>
                        <br />
                        <br />
                        <span className="about-text-bold">● Customer Support:</span>Our support team is ready to assist you with any issues, whether it’s related to platform navigation or understanding live GIFT Nifty data.<br />
                        <br />
                        <span className="about-text-bold">● Feedback:</span>Your feedback helps us improve our services. We want to know what you love about GIFTNifty.com.in and where we can improve.<br /><br />
                        <span className="about-text-bold">● Partnerships & Collaborations:</span> Interested in partnering with us? We’re open to collaboration ideas.
                        <br /><br />
                        <span className="about-text-bold">● Media Inquiries:</span> For press and media-related inquiries, reach out to us for detailed information.<br /><br />
                        <br />
                        <span className="about-text-bold">Response Time</span><br /><br />
                        We strive to respond to all inquiries within 24 hours. However, during peak times, responses may take a bit longer. Rest assured, we value your query and will address it as soon as possible.<br /><br />Thank you for choosing GIFTNifty.com.in! We look forward to assisting you and making your trading experience smooth and informed.
                    </p>
                </div>
            </div>
            <div style={{ marginTop: '30px', backgroundColor: '#181A23', display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                <div style={{ width: '100%', maxWidth: '1341px', marginTop: '30px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <img
                        className="logo-white"
                        alt="Logo white"
                        src={require("../assets/logo-white.png")}
                        style={{ cursor: 'pointer', width: '100%', maxWidth: '210px' }}
                        onClick={() => navigate("/mobile")}
                    />
                    <div style={{ marginTop: '30px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <p style={{
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontWeight: '600',
                            color: '#DFE0ED',
                            margin: '10px',
                            textTransform: 'uppercase',
                            cursor: "pointer"
                        }} onClick={() => navigate(`/mobile/about`)}>About</p>
                        <p style={{
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontWeight: '600',
                            color: '#DFE0ED',
                            margin: '10px',
                            textTransform: 'uppercase',
                            cursor: "pointer"
                        }} onClick={() => navigate(`/mobile/contact-us`)}>Contact Us</p>
                        <p style={{
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontWeight: '600',
                            color: '#DFE0ED',
                            margin: '10px',
                            textTransform: 'uppercase',
                            cursor: "pointer"
                        }} onClick={() => {
                            navigate(`/mobile/terms-and-conditions`);
                        }} >Terms and Conditions</p>
                        <p style={{
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontWeight: '600',
                            color: '#DFE0ED',
                            margin: '10px',
                            textTransform: 'uppercase',
                            cursor: "pointer"
                        }} onClick={() => {
                            navigate(`/mobile/privacy-policy`);
                        }} >Privacy Policy</p>
                        <p style={{
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontWeight: '600',
                            color: '#DFE0ED',
                            margin: '10px',
                            textTransform: 'uppercase',
                            cursor: "pointer"
                        }} onClick={() => navigate(`/mobile/disclaimer`)}>Disclaimer</p>
                        <p style={{
                            fontFamily: 'Poppins',
                            fontSize: '12px',
                            color: '#DFE0ED',
                            textAlign: 'center',
                            margin: '20px',
                            lineHeight: 'normal'
                        }}>This website is solely for stock market information purposes and is not affiliated with any official entities such as SGX, NSE, NSEIX, IFSC, GIFT City, or Nifty. It does not act as a financial advisor or influencer, and we do not offer trading or investment advice, tips,
                            recommendations, or strategies through our website, social media channels, or any other
                            medium.<br /><br />All users and members of this website are subject to our Disclaimer, Disclosure, Privacy
                            Policy, and Terms & Conditions. By using this website, you acknowledge and agree to all of the above terms and conditions.
                        </p>
                        <p style={{
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontWeight: '600',
                            color: '#DFE0ED',
                            margin: '20px',
                            cursor: 'pointer'
                        }} onClick={() => navigate("/mobile")}>© 2024 GIFTNIFTY</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactUsMobile;