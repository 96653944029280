import '../App.css';
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet"; // Import Helmet for managing document head
import { FaSearch } from "react-icons/fa";
import { FaChevronDown } from "react-icons/fa";

const AboutUsMobile = () => {
    const navigate = useNavigate();
    const [containerWidth, setContainerWidth] = useState(window.innerWidth - 40);
    const [isGiftNiftyOpen, setGiftNiftyOpen] = useState(false);

    useEffect(() => {
        // Update width on window resize
        const handleResize = () => {
            setContainerWidth(window.innerWidth - 40); // Adjust for padding
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleGiftNiftyToggle = () => {
        setGiftNiftyOpen(prev => !prev); // Toggle dropdown
    };

    const handleGiftNiftyMouseEnter = () => {
        setGiftNiftyOpen(true); // Open dropdown on hover
    };

    const handleGiftNiftyMouseLeave = () => {
        setGiftNiftyOpen(false); // Close dropdown on mouse leave
    };

    const handleClick = () => {
        navigate("/blogs");
    };

    return (
        <div className="desktop">
            <div
                style={{
                    height: '90px',
                    backgroundColor: '#181A23',
                    display: 'flex',
                    flexDirection: 'column', // Stack items vertically
                    alignItems: 'center', // Center items horizontally
                    justifyContent: 'center', // Center items vertically
                    width: '100%',
                    position: 'sticky',
                    top: 0,
                    zIndex: 1000,
                    padding: '10px 20px', // Add padding for left/right
                }}
            >
                <img
                    className="logo-white"
                    alt="Logo white"
                    src={require("../assets/logo-white.png")}
                    style={{ cursor: 'pointer', width: '150px', height: 'auto' }} // Adjust logo size
                    onClick={() => {
                        navigate("/mobile");
                    }}
                />
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-around', // Space out navigation items
                        flexWrap: 'wrap', // Allow wrapping
                        gap: '15px', // Use gap for spacing between elements
                        marginTop: '10px', // Add some margin above the nav items
                        width: '100%', // Full width for nav items
                    }}
                >
                    <p
                        onClick={() => {
                            navigate("/mobile/blogs");
                        }}
                        style={{
                            cursor: "pointer",
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontWeight: '600',
                            color: '#DFE0ED',
                            margin: '0', // Remove margin
                        }}
                    >
                        BLOGS
                    </p>
                    <p
                        onClick={() => {
                            navigate("/mobile/news");
                        }}
                        style={{
                            cursor: "pointer",
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontWeight: '600',
                            color: '#DFE0ED',
                            margin: '0', // Remove margin
                        }}
                    >
                        NEWS
                    </p>
                    <div
    onMouseEnter={handleGiftNiftyMouseEnter}
    onMouseLeave={handleGiftNiftyMouseLeave}
    style={{ position: 'relative' }}
>
    <p
        style={{
            fontFamily: 'Poppins',
            fontSize: '16px',
            fontWeight: '600',
            color: '#DFE0ED',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            margin: '0', // Remove margin
        }}
    >
        GIFT NIFTY <FaChevronDown style={{ marginLeft: '10px' }} />
    </p>
    {isGiftNiftyOpen && (
        <div
            style={{
                position: 'absolute',
                backgroundColor: '#181A23',
                borderRadius: '5px',
                padding: '10px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                zIndex: 100,
                width: '200px', // Adjust width for mobile
            }}
        >
            {[
                { text: "What is GIFT NIFTY?", link: "https://insider.giftnifty.com.in/blog/what-is-gift-nifty/" },
                { text: "GIFT NIFTY VS SGX NIFTY", link: "https://insider.giftnifty.com.in/blog/gift-nifty-vs-sgx-nifty/" },
                { text: "GIFT NIFTY VS NIFTY 50", link: "https://insider.giftnifty.com.in/blog/gift-nifty-vs-nifty-50/" },
                { text: "GIFT NIFTY Timing", link: "https://insider.giftnifty.com.in/blog/gift-nifty-timings/" },
            ].map((item, index) => (
                <a
                    key={index}
                    href={item.link}
                    target="_blank"
                    style={{
                        fontFamily: 'Poppins',
                        fontSize: '16px',
                        fontWeight: '600',
                        color: '#DFE0ED',
                        margin: '10px 0', // Adjust margin for mobile
                        cursor: 'pointer',
                        display: 'block', // Ensure each link is displayed in a block
                    }}
                >
                    {item.text}
                </a>
            ))}
        </div>
    )}
</div>
                    <FaSearch
                        onClick={() => {
                            navigate("/mobile/search");
                        }}
                        size={20}
                        style={{ color: '#8C52FF' }} // Removed margin for icon
                    />
                </div>
            </div>
            <div style={{
                display: 'flex', flexDirection: 'column',
            }}>
                <div className="main-axis" style={{
                    display: 'flex',
                    width: `${containerWidth}px`, // Set the width based on the state
                    flexDirection: 'column',
                    flexWrap: 'nowrap',
                    justifyContent: 'space-between', // Space out items evenly
                }}>
                    <h5 className="content-title" style={{
                        marginLeft: '0px',
                        marginRight: '0px',
                    }}>About</h5>
                    <p className="about-text" style={{
                        marginLeft: '0px',
                        marginRight: '0px',
                        lineHeight: 'normal', width: '100%', fontSize: '18px', color: 'white'
                    }}>
                        GIFT Nifty is a derivative of the Nifty index, traded on the GIFT City exchange in India. In simpler terms, GIFT Nifty represents a futures contract that can be traded on this platform. While Indian traders deal with Nifty 50 futures in the Indian stock exchanges, international investors can engage with GIFT Nifty in GIFT City.<br /><br />
                        <span className="about-text-bold">How is GIFT Nifty different from Indian Nifty?<br /></span>
                        The main distinction between GIFT Nifty and Indian Nifty is that GIFT Nifty is a futures<br />
                        contract traded in GIFT City, whereas Indian Nifty is traded solely on the National Stock Exchange (NSE) in India. Another notable difference lies in the contract size: the lot size for Indian Nifty is 50, while there are no such requirements for GIFT Nifty.<br />
                        GIFT Nifty benefits from high trading volumes as it operates as an active trading platform in GIFT City, providing a unique trading environment for investors. This platform offers extended trading hours compared to the traditional Indian exchanges, making it an attractive option for traders seeking flexibility.<br /><br />
                        <span className="about-text-bold">How does GIFT Nifty impact the Indian Market?<br /></span>
                        There is a time difference between Indian Nifty and GIFT Nifty, as the GIFT City market<br />
                        opens approximately two and a half hours before the Indian market. GIFT Nifty operates for extended hours, making it valuable for predicting Indian Nifty’s pre-market behavior. Investors can gauge market fluctuations and anticipate how Nifty trading will begin in India. Checking GIFT Nifty each morning gives traders insights into whether the Indian markets might open positively or negatively.<br /><br />
                        <span className="about-text-bold">What are the market timings for GIFT Nifty?<br /></span>
                        Unlike the Indian markets, which are open for about six hours (9:00 AM to 3:30 PM IST), GIFT<br />
                        Nifty operates for nearly 21 hours, providing ample opportunities for traders.<br />
                    </p>
                </div>
            </div>
            <div style={{ marginTop: '30px', backgroundColor: '#181A23', display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                <div style={{ width: '100%', maxWidth: '1341px', marginTop: '30px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <img
                        className="logo-white"
                        alt="Logo white"
                        src={require("../assets/logo-white.png")}
                        style={{ cursor: 'pointer', width: '100%', maxWidth: '210px' }}
                        onClick={() => navigate("/mobile")}
                    />
                    <div style={{ marginTop: '30px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <p style={{
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontWeight: '600',
                            color: '#DFE0ED',
                            margin: '10px',
                            textTransform: 'uppercase',
                            cursor: "pointer"
                        }} onClick={() => navigate(`/mobile/about`)}>About</p>
                        <p style={{
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontWeight: '600',
                            color: '#DFE0ED',
                            margin: '10px',
                            textTransform: 'uppercase',
                            cursor: "pointer"
                        }} onClick={() => navigate(`/mobile/contact-us`)}>Contact Us</p>
                        <p style={{
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontWeight: '600',
                            color: '#DFE0ED',
                            margin: '10px',
                            textTransform: 'uppercase',
                            cursor: "pointer"
                        }} onClick={() => {
                            navigate(`/mobile/terms-and-conditions`);
                        }} >Terms and Conditions</p>
                        <p style={{
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontWeight: '600',
                            color: '#DFE0ED',
                            margin: '10px',
                            textTransform: 'uppercase',
                            cursor: "pointer"
                        }} onClick={() => {
                            navigate(`/mobile/privacy-policy`);
                        }} >Privacy Policy</p>
                        <p style={{
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontWeight: '600',
                            color: '#DFE0ED',
                            margin: '10px',
                            textTransform: 'uppercase',
                            cursor: "pointer"
                        }} onClick={() => navigate(`/mobile/disclaimer`)}>Disclaimer</p>
                        <p style={{
                            fontFamily: 'Poppins',
                            fontSize: '12px',
                            color: '#DFE0ED',
                            textAlign: 'center',
                            margin: '20px',
                            lineHeight: 'normal'
                        }}>This website is solely for stock market information purposes and is not affiliated with any official entities such as SGX, NSE, NSEIX, IFSC, GIFT City, or Nifty. It does not act as a financial advisor or influencer, and we do not offer trading or investment advice, tips,
                            recommendations, or strategies through our website, social media channels, or any other
                            medium.<br /> <br />All users and members of this website are subject to our Disclaimer, Disclosure, Privacy
                            Policy, and Terms & Conditions. By using this website, you acknowledge and agree to all of the above terms and conditions.
                        </p>
                        <p style={{
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontWeight: '600',
                            color: '#DFE0ED',
                            margin: '20px',
                            cursor: 'pointer'
                        }} onClick={() => navigate("/mobile")}>© 2024 GIFTNIFTY</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutUsMobile;