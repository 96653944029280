import React, { useState } from "react";

// FAQ Component
const FAQAccordion = ({ faq }) => {
    const [isOpen, setIsOpen] = useState(false);

    // Toggle the visibility of the answer
    const toggleFAQ = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div style={{
            marginBottom: '15px',
            borderBottom: '1px solid #ccc',
            paddingBottom: '10px',
            width: '100%',
            cursor: 'pointer'
        }}>
            {/* Question */}
            <h3
                onClick={toggleFAQ}
                style={{
                    fontWeight: '600',
                    color: '#DFE0ED',
                    fontSize: '18px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
            >
                {faq.title}
                <span style={{
                    marginLeft: '10px', fontSize: '18px',
                    fontSize: '18px',
                    lineHeight: 'normal',
                    letterSpacing: 'normal',
                    fontFamily: 'Poppins'
                }}>
                    {isOpen ? '-' : '+'} {/* Expand/Collapse icon */}
                </span>
            </h3>

            {/* Answer (show/hide based on isOpen state) */}
            {isOpen && (
                <div style={{
                    marginTop: '10px',
                    fontSize: '18px',
                    lineHeight: 'normal',
                    letterSpacing: 'normal',
                    color: '#B0B3C3',
                    fontFamily: 'Poppins'
                }}>
                    {faq.content}
                </div>
            )}
        </div>
    );
};

export default FAQAccordion;