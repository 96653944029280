import '../App.css';
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import { FaChevronDown } from "react-icons/fa";

const GiftNiftyMobile = () => {
    const navigate = useNavigate();
    const [containerWidth, setContainerWidth] = useState(window.innerWidth - 40);

    const [isGiftNiftyOpen, setGiftNiftyOpen] = useState(false);
    const location = useLocation();

    useEffect(() => {
        // Update width on window resize
        const handleResize = () => {
            setContainerWidth(window.innerWidth - 40); // Adjust for padding
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // Function to scroll to the section
    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            // Get the position of the section and subtract 50px from it
            const yOffset = -100; // 50px offset
            const yPosition = section.getBoundingClientRect().top + window.pageYOffset + yOffset;

            // Scroll to the calculated position
            window.scrollTo({ top: yPosition, behavior: "smooth" });
        }
    };

    // Scroll to the correct section based on URL fragment
    useEffect(() => {
        if (location.hash) {
            const sectionId = location.hash.substring(1); // Remove the '#' from the hash
            scrollToSection(sectionId);
        }
    }, [location]);

    const handleGiftNiftyToggle = () => {
        setGiftNiftyOpen(prev => !prev); // Toggle dropdown
    };

    const handleGiftNiftyMouseEnter = () => {
        setGiftNiftyOpen(true); // Open dropdown on hover
    };

    const handleGiftNiftyMouseLeave = () => {
        setGiftNiftyOpen(false); // Close dropdown on mouse leave
    };

    const handleClick = () => {
        navigate("/blogs");
    };

    return (
        <div className="desktop">
            <div
                style={{
                    height: '90px',
                    backgroundColor: '#181A23',
                    display: 'flex',
                    flexDirection: 'column', // Stack items vertically
                    alignItems: 'center', // Center items horizontally
                    justifyContent: 'center', // Center items vertically
                    width: '100%',
                    position: 'sticky',
                    top: 0,
                    zIndex: 1000,
                    padding: '10px 20px', // Add padding for left/right
                }}
            >
                <img
                    className="logo-white"
                    alt="Logo white"
                    src={require("../assets/logo-white.png")}
                    style={{ cursor: 'pointer', width: '150px', height: 'auto' }} // Adjust logo size
                    onClick={() => {
                        navigate("/mobile");
                    }}
                />
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-around', // Space out navigation items
                        flexWrap: 'wrap', // Allow wrapping
                        gap: '15px', // Use gap for spacing between elements
                        marginTop: '10px', // Add some margin above the nav items
                        width: '100%', // Full width for nav items
                    }}
                >
                    <p
                        onClick={() => {
                            navigate("/mobile/blogs");
                        }}
                        style={{
                            cursor: "pointer",
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontWeight: '600',
                            color: '#DFE0ED',
                            margin: '0', // Remove margin
                        }}
                    >
                        BLOGS
                    </p>
                    <p
                        onClick={() => {
                            navigate("/mobile/news");
                        }}
                        style={{
                            cursor: "pointer",
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontWeight: '600',
                            color: '#DFE0ED',
                            margin: '0', // Remove margin
                        }}
                    >
                        NEWS
                    </p>
                    <div
                        onMouseEnter={handleGiftNiftyMouseEnter}
                        onMouseLeave={handleGiftNiftyMouseLeave}
                        style={{ position: 'relative' }}
                    >
                        <p
                            style={{
                                fontFamily: 'Poppins',
                                fontSize: '16px',
                                fontWeight: '600',
                                color: '#DFE0ED',
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                margin: '0', // Remove margin
                            }}
                        >
                            GIFT NIFTY <FaChevronDown style={{ marginLeft: '10px' }} />
                        </p>
                        {isGiftNiftyOpen && (
                            <div
                                style={{
                                    position: 'absolute',
                                    backgroundColor: '#181A23',
                                    borderRadius: '5px',
                                    padding: '10px',
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                                    zIndex: 100,
                                    width: '200px', // Adjust width for mobile
                                }}
                            >
                                {["What is GIFT NIFTY?", "GIFT NIFTY VS SGX NIFTY", "GIFT NIFTY VS NIFTY 50", "GIFT NIFTY Timings"].map((item, index) => (
                                    <p
                                        key={index}
                                        style={{
                                            fontFamily: 'Poppins',
                                            fontSize: '16px',
                                            fontWeight: '600',
                                            color: '#DFE0ED',
                                            margin: '10px 0', // Adjust margin for mobile
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                            navigate(`/mobile/giftnifty#${item.toLowerCase().replace(/ /g, '')}`); // Dynamic routing
                                        }}
                                    >
                                        {item}
                                    </p>
                                ))}
                            </div>
                        )}
                    </div>
                    <FaSearch
                        onClick={() => {
                            navigate("/mobile/search");
                        }}
                        size={20}
                        style={{ color: '#8C52FF' }} // Removed margin for icon
                    />
                </div>
            </div>
            <div style={{
                display: 'flex', flexDirection: 'column',
            }}>
                <div className="main-axis" style={{
                    display: 'flex',
                    width: `${containerWidth}px`, // Set the width based on the state
                    flexDirection: 'column',
                    flexWrap: 'nowrap',
                    justifyContent: 'space-between', // Space out items evenly
                }}>
                    <h5 className="content-title" style={{
                        marginLeft: '0px',
                        marginRight: '0px',
                    }}>GIFT NIFTY </h5>
                    <p className="about-text" style={{
                        marginLeft: '0px',
                        marginRight: '0px',
                        lineHeight: 'normal', width: '100%', fontSize: '18px', color: 'white'
                    }}>
                        <span id="wgn" className="about-text-bold">What is GIFT NIFTY?<br /><br />
                            GIFTNifty.com.in</span> is built with a passion for simplifying financial markets and making GIFT Nifty futures trading accessible to all. Whether you’re an experienced trader or just starting your journey in the futures market, our goal is to equip you with the tools, insights, and support you need to make informed trading decisions.<br />
                        GIFT Nifty futures have emerged as a key instrument for global investors to access India’s vibrant and fast-growing stock market. As a specialized platform, we are committed to helping you navigate this dynamic market with ease and confidence.<br /><br />
                        <span className="about-text-bold">Our Mission</span><br /><br />
                        Our mission is simple: to provide traders and investors with accurate, <span className="about-text-bold">real-time GIFT Nifty data, in-depth analysis, and easy-to-understand resources that make trading in GIFT Nifty futures more transparent and straightforward.</span><br />
                        We are committed to:<br />
                        <br />
                        <span id="gnvs" className="about-text-bold">GIFT NIFTY vs. SGX NIFTY</span><br /><br />
                        At GIFTNifty.com.in, we offer a range of services to enhance your trading experience:<br />
                        <span className="about-text-bold">1. Live GIFT Nifty Index:</span> Get access to real-time prices, charts, and trends that help you track
                        the performance of GIFT Nifty futures effortlessly.<br />
                        <span className="about-text-bold">2. Market News & Insights:</span> Stay updated with the latest happenings in the global and Indian
                        markets, including factors that influence GIFT Nifty.<br />
                        <span className="about-text-bold">3. How-to Guides & Tips:</span> Learn the ins and outs of GIFT Nifty trading with our in-depth guides
                        designed for both beginners and seasoned traders.<br />
                        <span className="about-text-bold">4. Interactive Tools:</span> Utilize our trading tools and calculators to make better trading decisions,
                        from predicting market trends to understanding price movements.<br />
                        <span className="about-text-bold">5. Educational Resources:</span> Our blog covers everything from trading strategies and risk
                        management to the technical analysis of GIFT Nifty futures.<br /><br />
                        <span id="gnvs2" className="about-text-bold">GIFT NIFTY vs. NIFTY 50</span><br />
                        <br />
                        GIFT Nifty is a gateway for international traders to participate in India’s growth story through derivatives. It allows foreign investors to trade Nifty 50 futures without needing direct access to Indian exchanges. With GIFT Nifty, traders benefit from extended trading hours, access to global markets, and seamless transactions through GIFT City, India’s premier financial hub.<br /><br />
                        <span id="gntt" className="about-text-bold">GIFT NIFTY Trading Timings</span><br /><br />
                        We envision a world where trading in GIFT Nifty is seamless, transparent, and accessible to traders across the globe. By building a robust platform that offers real-time data, actionable insights, and a supportive community, we aim to empower every trader to confidently navigate the GIFT Nifty market.<br /><br />
                    </p>
                </div>
            </div>
            <div style={{ marginTop: '30px', backgroundColor: '#181A23', display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                <div style={{ width: '100%', maxWidth: '1341px', marginTop: '30px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <img
                        className="logo-white"
                        alt="Logo white"
                        src={require("../assets/logo-white.png")}
                        style={{ cursor: 'pointer', width: '100%', maxWidth: '200px' }}
                        onClick={() => navigate("/mobile")}
                    />
                    <div style={{ marginTop: '30px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <p style={{
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontWeight: '600',
                            color: '#DFE0ED',
                            margin: '10px',
                            textTransform: 'uppercase',
                            cursor: "pointer"
                        }} onClick={() => navigate(`/mobile/about`)}>About</p>
                        <p style={{
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontWeight: '600',
                            color: '#DFE0ED',
                            margin: '10px',
                            textTransform: 'uppercase',
                            cursor: "pointer"
                        }} onClick={() => navigate(`/mobile/contact-us`)}>Contact Us</p>
                        <p style={{
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontWeight: '600',
                            color: '#DFE0ED',
                            margin: '10px',
                            textTransform: 'uppercase',
                            cursor: "pointer"
                        }} onClick={() => {
                            navigate(`/mobile/terms-and-conditions`);
                        }} >Terms and Conditions</p>
                        <p style={{
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontWeight: '600',
                            color: '#DFE0ED',
                            margin: '10px',
                            textTransform: 'uppercase',
                            cursor: "pointer"
                        }} onClick={() => {
                            navigate(`/mobile/privacy-policy`);
                        }} >Privacy Policy</p>
                        <p style={{
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontWeight: '600',
                            color: '#DFE0ED',
                            margin: '10px',
                            textTransform: 'uppercase',
                            cursor: "pointer"
                        }} onClick={() => navigate(`/mobile/disclaimer`)}>Disclaimer</p>
                        <p style={{
                            fontFamily: 'Poppins',
                            fontSize: '12px',
                            color: '#DFE0ED',
                            textAlign: 'center',
                            margin: '20px',
                            lineHeight: 'normal'
                        }}>This website is solely for stock market information purposes and is not affiliated with any official entities such as SGX, NSE, NSEIX, IFSC, GIFT City, or Nifty. It does not act as a financial advisor or influencer, and we do not offer trading or investment advice, tips,
                            recommendations, or strategies through our website, social media channels, or any other
                            medium.<br />All users and members of this website are subject to our Disclaimer, Disclosure, Privacy
                            Policy, and Terms & Conditions. By using this website, you acknowledge and agree to all of the above terms and conditions.
                        </p>
                        <p style={{
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontWeight: '600',
                            color: '#DFE0ED',
                            margin: '20px',
                            cursor: 'pointer'
                        }} onClick={() => navigate("/mobile")}>© 2024 GIFTNIFTY</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GiftNiftyMobile;