import './App.css';
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet"; // Import Helmet for managing document head
import { FaSearch } from "react-icons/fa";
import { FaChevronDown } from "react-icons/fa";

const Disclaimer = () => {
  const navigate = useNavigate();
  const [isGiftNiftyOpen, setGiftNiftyOpen] = useState(false);

  const handleGiftNiftyToggle = () => {
    setGiftNiftyOpen(prev => !prev); // Toggle dropdown
  };

  const handleGiftNiftyMouseEnter = () => {
    setGiftNiftyOpen(true); // Open dropdown on hover
  };

  const handleGiftNiftyMouseLeave = () => {
    setGiftNiftyOpen(false); // Close dropdown on mouse leave
  };

  const handleClick = () => {
    navigate("/blogs");
  };

  return (
    <div className="desktop">
      <Helmet>

        <title>GiftNifty.com.in Disclaimer</title>

        <meta name="description" content="Read the disclaimer for Gift Nifty. Understand the terms and conditions for using live index prices and trading updates for GIFT Nifty." />
        <meta name="keywords" content="GIFT Nifty, GIFT Nifty Disclaimer, Trading Terms, GIFT Nifty Terms and Conditions, Legal Disclaimer" />
        <meta name="author" content="Gift Nifty" />

        <meta property="og:title" content="GiftNifty.com.in Disclaimer" />
        <meta property="og:description" content="Understand the legal disclaimer for using Gift Nifty’s live index prices and updates. Stay informed about the terms and conditions." />
        <meta property="og:url" content="https://giftnifty.com.in/disclaimer" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="./assets/logo-white.png" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="GiftNifty.com.in Disclaimer" />
        <meta name="twitter:description" content="Read the disclaimer for Gift Nifty. Understand the terms and conditions for using live index prices and trading updates." />
        <meta name="twitter:image" content="./assets/logo-white.png" />

        <link rel="icon" href="%PUBLIC_URL%/favicon.ico" />
        <link rel="apple-touch-icon" href="%PUBLIC_URL%/logo192.png" />
        <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />

        <script async src="https://www.googletagmanager.com/gtag/js?id=G-4RXK10F79N"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag() { dataLayer.push(arguments); }
            gtag('js', new Date());
            gtag('config', 'G-4RXK10F79N');
          `}
        </script>
      </Helmet>

      <div style={{
        height: '90px',
        backgroundColor: '#181A23',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        display: 'flex',
        width: '100%',
        position: 'sticky',
        top: 0,
        zIndex: 1000
      }}>
        <div style={{ width: '1341px', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between', display: 'flex' }}>
          <img className="logo-white" alt="Logo white" src={require("./assets/logo-white.png")} style={{ cursor: 'pointer' }} onClick={() => {
            navigate("/");
          }} />
          <div style={{ alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between', display: 'flex' }}>
            <p onClick={() => {
              navigate("/blogs");
            }} style={{ cursor: "pointer", fontFamily: 'Poppins', fontSize: '16px', fontWeight: '600', marginLeft: '20px', marginRight: '20px', color: '#DFE0ED' }}>BLOGS</p>
            <p onClick={() => {
              navigate("/news");
            }} style={{ cursor: "pointer", fontFamily: 'Poppins', fontSize: '16px', fontWeight: '600', marginLeft: '20px', marginRight: '20px', color: '#DFE0ED' }}>NEWS</p>
            <div onMouseEnter={handleGiftNiftyMouseEnter} onMouseLeave={handleGiftNiftyMouseLeave} style={{ position: 'relative' }}>
              <p style={{ fontFamily: 'Poppins', fontSize: '16px', fontWeight: '600', color: '#DFE0ED', marginLeft: '20px', marginRight: '20px', cursor: 'pointer', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>GIFT NIFTY <FaChevronDown style={{ marginLeft: '10px' }} /></p>
              {isGiftNiftyOpen && (
                <div style={{
                  position: 'absolute',
                  backgroundColor: '#181A23',
                  borderRadius: '5px',
                  padding: '10px',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                  zIndex: 100,
                  width: '300px'
                }}>
                  <p
                    style={{
                      fontFamily: 'Poppins',
                      fontSize: '16px',
                      fontWeight: '600',
                      color: '#DFE0ED',
                      margin: '20px',
                      cursor: 'pointer',
                    }}
                  >
                    <a
                      href="https://insider.giftnifty.com.in/blog/what-is-gift-nifty/"
                      target="_blank"
                      rel="dofollow"
                      style={{ color: '#DFE0ED', textDecoration: 'none' }}
                    >
                      What is GIFT NIFTY?
                    </a>
                  </p>

                  <p
                    style={{
                      fontFamily: 'Poppins',
                      fontSize: '16px',
                      fontWeight: '600',
                      color: '#DFE0ED',
                      margin: '20px',
                      cursor: 'pointer',
                    }}
                  >
                    <a
                      href="https://insider.giftnifty.com.in/blog/gift-nifty-vs-sgx-nifty/"
                      target="_blank"
                      rel="dofollow"
                      style={{ color: '#DFE0ED', textDecoration: 'none' }}
                    >
                      GIFT NIFTY VS SGX NIFTY
                    </a>
                  </p>

                  <p
                    style={{
                      fontFamily: 'Poppins',
                      fontSize: '16px',
                      fontWeight: '600',
                      color: '#DFE0ED',
                      margin: '20px',
                      cursor: 'pointer',
                    }}
                  >
                    <a
                      href="https://insider.giftnifty.com.in/blog/gift-nifty-vs-nifty-50/"
                      target="_blank"
                      rel="dofollow"
                      style={{ color: '#DFE0ED', textDecoration: 'none' }}
                    >
                      GIFT NIFTY VS NIFTY 50
                    </a>
                  </p>

                  <p
                    style={{
                      fontFamily: 'Poppins',
                      fontSize: '16px',
                      fontWeight: '600',
                      color: '#DFE0ED',
                      margin: '20px',
                      cursor: 'pointer',
                    }}
                  >
                    <a
                      href="https://insider.giftnifty.com.in/blog/gift-nifty-timings/"
                      target="_blank"
                      rel="dofollow"
                      style={{ color: '#DFE0ED', textDecoration: 'none' }}
                    >
                      GIFT NIFTY Timing
                    </a>
                  </p>
                </div>
              )}
            </div>
            <FaSearch onClick={() => {
              navigate("/search");
            }} size={20} style={{ color: '#8C52FF', marginLeft: '20px', marginRight: '20px' }} />
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div className="main-axis" style={{ width: '1341px', borderRadius: '20px', flexDirection: 'column', paddingBottom: '30px' }}>
          <div className="main-axis" style={{ flexDirection: 'column', display: 'flex' }}>
            <p className="about-text" style={{
              lineHeight: 'normal', width: '80%', fontSize: '18px', color: 'white'
            }}>
              <span className="about-text-bold">Disclaimer and Risk Disclosure for This Website</span><br /><br />
              <span className="about-text-bold">Independent Nature of the Website:</span><br />
              This website is an independent platform solely dedicated to providing stock market information, educational content, and knowledge sharing. It is not associated with or endorsed by any official entities such as SGX, NSE, NSEIX, IFSC, GIFT City, Nifty, Nifty 50, Nifty 100, or similar brands. The information presented on this website is for educational and informational purposes only.<br /><br />
              <span className="about-text-bold">Copyright and Trademark Notice:</span><br />
              GIFT NIFTY, its logo, and other identifying marks are and shall remain the copyright,
              trademarks, or trade names, and exclusive property of GIFT NIFTY. Any unauthorized use of these marks is unlawful. Other trademarks on the services are the property of their respective owners. All content within the services, including, without limitation, all programs, interface layout, interface text, documentation, and graphics, is the copyrighted property of GIFT NIFTY and is protected by international copyright law.<br /><br />
              <span className="about-text-bold">Risk Disclosure:</span><br />
              Trading in equity derivatives, such as Futures and Options, involves significant risks and
              can result in substantial financial losses. According to a study conducted by SEBI dated January 25, 2023, titled “Analysis of Profit and Loss of Individual Traders dealing in equity Futures and Options (F&O) Segment,” the following findings were noted:<br /><br />
              ■ • Approximately <span className="about-text-bold">9 out of 10 individual traders</span> in the equity Futures and Options segment incurred net losses.<br />
              ■ • On average, those who recorded losses experienced a net trading loss of close to <span className="about-text-bold">₹50,000.</span><br />
              ■ • Additionally, loss-makers expended an average of <span className="about-text-bold">28% of their net trading losses on transaction costs.</span><br />
              ■ • Those making net trading profits incurred transaction costs between <span className="about-text-bold">15% to 50% of their profits.</span><br /><br />
              <span className="about-text-bold">Market Risks:</span><br />
              All equity investments are subject to <span className="about-text-bold">100% market risks</span>. It is crucial to consult with a
              qualified financial advisor before making any trading or investment decisions. This website serves only for educational and informational purposes; all posts, updates, and discussions are intended for educational learning. Users should carefully assess their cash positions and risk-bearing capacity before following any advice or comments made on this website.<br /><br />
              <span className="about-text-bold">Understanding Market Risks:</span><br />

              Investing in the stock market carries inherent risks, including the possibility of losing some or all of your investment. You acknowledge and agree that you understand these risks by engaging with the content on this website. Profits and losses are common in share trading, and it is essential to act wisely and calculate risks.<br /><br />
              <span className="about-text-bold">Comments and Content on Sub-domain:</span><br />
              While our main website does not feature a comment section, our sub-domain, where we
              share knowledge base articles and news, operates on WordPress. Please note that the content shared there is user-generated. We have not reviewed all discussions or comments and are not responsible for their content. The inclusion of any links does not imply our endorsement, and users should proceed at their own risk.<br /><br />
              <span className="about-text-bold">Limitation of Liability:</span><br />
              We will not be responsible for lost profits, revenues, data, financial losses, or any indirect,
              special, consequential, exemplary, or punitive damages arising from your use of this website. In all cases, we will not be liable for any loss or damage that occurs from your engagement with this site.<br /><br />
              <span className="about-text-bold">No Financial Advice:</span><br />
              The website’s administrators and content contributors do not provide financial advice or
              recommendations. All users of this website agree to these terms and conditions and acknowledge that the site is not registered with SEBI or any other regulatory authority.</p>
          </div>
        </div>
      </div>
      <div style={{ marginTop: '30px', backgroundColor: '#181A23', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', display: 'flex', width: '100%' }}>
        <div style={{ width: '1341px', marginTop: '30px', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', display: 'flex', }}>
          <img className="logo-white" alt="Logo white" src={require("./assets/logo-white.png")} style={{ cursor: 'pointer' }} onClick={() => {
            navigate("/");
          }} />
          <div style={{ marginTop: '30px', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', display: 'flex', }} >
            <div style={{ marginTop: '30px', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', display: 'flex', }} >
              <p style={{
                fontFamily: 'Poppins',
                fontSize: '16px',
                fontWeight: '600',
                marginLeft: '20px',
                marginRight: '20px',
                color: '#DFE0ED',
                textTransform: 'uppercase', cursor: "pointer"
              }} onClick={() => {
                navigate(`/about`);
              }} >About</p>
              <p style={{
                fontFamily: 'Poppins',
                fontSize: '16px',
                fontWeight: '600',
                marginLeft: '20px',
                marginRight: '20px',
                color: '#DFE0ED',
                textTransform: 'uppercase', cursor: "pointer"
              }} onClick={() => {
                navigate(`/contact-us`);
              }} >Contact Us</p>
              <p style={{
                fontFamily: 'Poppins',
                fontSize: '16px',
                fontWeight: '600',
                marginLeft: '20px',
                marginRight: '20px',
                color: '#DFE0ED',
                textTransform: 'uppercase', cursor: "pointer"
              }} onClick={() => {
                navigate(`/terms-and-conditions`);
              }} >Terms and Conditions</p>
              <p style={{
                fontFamily: 'Poppins',
                fontSize: '16px',
                fontWeight: '600',
                marginLeft: '20px',
                marginRight: '20px',
                color: '#DFE0ED',
                textTransform: 'uppercase', cursor: "pointer"
              }} onClick={() => {
                navigate(`/privacy-policy`);
              }} >Privacy Policy</p>
              <p style={{
                fontFamily: 'Poppins',
                fontSize: '16px',
                fontWeight: '600',
                color: '#DFE0ED',
                marginLeft: '20px',
                marginRight: '20px',
                textTransform: 'uppercase', cursor: "pointer"
              }} onClick={() => {
                navigate(`/disclaimer`);
              }} >Disclaimer</p>
            </div>
            <p style={{
              fontFamily: 'Poppins',
              fontSize: '12px',
              color: '#DFE0ED',
              textAlign: 'center',
              margin: '20px',
              lineHeight: 'normal',
              paddingLeft: '150px',
              paddingRight: '150px'
            }}>This website is solely for stock market information purposes and is not affiliated with any official entities such as SGX, NSE, NSEIX, IFSC, GIFT City, or Nifty. It does not act as a financial advisor or influencer, and we do not offer trading or investment advice, tips,
              recommendations, or strategies through our website, social media channels, or any other
              medium.<br /> <br />All users and members of this website are subject to our Disclaimer, Disclosure, Privacy
              Policy, and Terms & Conditions. By using this website, you acknowledge and agree to all of the above terms and conditions.
            </p>
            <p style={{
              fontFamily: 'Poppins',
              fontSize: '16px',
              fontWeight: '600',
              color: '#DFE0ED',
              margin: '20px',
              cursor: 'pointer'
            }} onClick={() => {
              navigate("/");
            }} >© 2024 GIFTNIFTY</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Disclaimer;