import './App.css';
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaSearch, FaChevronDown } from "react-icons/fa";
import { Helmet } from "react-helmet"; // Import Helmet for managing document head

const AllBlogs = () => {
    const [blogs, setBlogs] = useState([]);
    const [tags, setTags] = useState([]); // State for storing tags
    const navigate = useNavigate();
    const [isGiftNiftyOpen, setGiftNiftyOpen] = useState(false);

    const handleGiftNiftyToggle = () => {
        setGiftNiftyOpen(prev => !prev);
    };

    const handleGiftNiftyMouseEnter = () => {
        setGiftNiftyOpen(true);
    };

    const handleGiftNiftyMouseLeave = () => {
        setGiftNiftyOpen(false);
    };

    useEffect(() => {
        // Fetch tags
        fetch('https://insider.giftnifty.com.in/wp-json/wp/v2/tags')
            .then(response => response.json())
            .then(tagData => {
                const formattedTags = tagData.map(tag => ({
                    id: tag.id,
                    name: tag.name
                }));
                setTags(formattedTags);
            });
        fetch('https://insider.giftnifty.com.in/wp-json/wp/v2/posts')
            .then(response => response.json())
            .then(data => {
                const formattedBlogs = data
                    .filter(blog => blog.categories.includes(2)) // Filter blogs with category ID 2
                    .map(blog => ({
                        id: blog.id,
                        title: blog.title.rendered,
                        description: blog.excerpt.rendered,
                        thumbnail: blog.uagb_featured_image_src["2048x2048"][0],
                        createdAt: blog.date,
                        tag: blog.tags[0], // First tag ID (we will map this to a tag name)
                        link: blog.link, // Blog link for opening in a new tab
                    }));
                setBlogs(formattedBlogs);
            });
    }, []);

    // Helper function to get tag name by ID
    const getTagName = (tagId) => {
        const tag = tags.find(t => t.id === tagId);
        return tag ? tag.name : 'No Tag';
    };

    // Get the latest blog and the rest
    const latestBlog = blogs[0];
    const otherBlogs = blogs.slice(1);

    const openBlogInNewTab = (url) => {
        window.open(url, '_blank', 'noopener,noreferrer'); // Opens the blog in a new tab
    };

    return (
        <div className="desktop" style={{ height: blogs.length > 0 ? null : '100vh' }}>
            <Helmet>
        {/* Primary Meta Tags */}
        <title>Gift Nifty Blogs - GiftNifty.com.in</title>
        <meta name="description" content="Explore detailed insights, updates, and analysis on Gift Nifty. Stay informed with our expert-curated blogs tailored for traders in India." />
        <meta name="keywords" content="Gift Nifty Blogs, Gift Nifty Insights, Gift Nifty Updates, Gift Nifty Analysis, Gift Nifty for Traders" />
        <meta name="author" content="Gift Nifty" />
        
        {/* Open Graph / Facebook Meta Tags */}
        <meta property="og:title" content="Gift Nifty Blogs - GiftNifty.com.in" />
        <meta property="og:description" content="Explore detailed insights, updates, and analysis on Gift Nifty. Stay informed with our expert-curated blogs tailored for traders in India." />
        <meta property="og:url" content="https://giftnifty.com.in/blogs" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="./assets/logo-white.png" />
        
        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Gift Nifty Blogs - GiftNifty.com.in" />
        <meta name="twitter:description" content="Explore detailed insights, updates, and analysis on Gift Nifty. Stay informed with our expert-curated blogs tailored for traders in India." />
        <meta name="twitter:image" content="./assets/logo-white.png" />
        
        {/* Favicon */}
        <link rel="icon" href="%PUBLIC_URL%/favicon.ico" />
        <link rel="apple-touch-icon" href="%PUBLIC_URL%/logo192.png" />
        <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />
        
        {/* Google Analytics */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-4RXK10F79N"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag() { dataLayer.push(arguments); }
            gtag('js', new Date());
            gtag('config', 'G-4RXK10F79N');
          `}
        </script>
      </Helmet>
            <div style={{
                height: '90px',
                backgroundColor: '#181A23',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'center',
                display: 'flex',
                width: '100%',
                position: 'sticky',
                top: 0,
                zIndex: 1000
            }}>
                <div style={{ width: '1341px', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between', display: 'flex' }}>
                    <img className="logo-white" alt="Logo white" src={require("./assets/logo-white.png")} style={{ cursor: 'pointer' }} onClick={() => {
                        navigate("/");
                    }} />
                    <div style={{ alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between', display: 'flex' }}>
                        <p onClick={() => {
                            navigate("/blogs");
                        }} style={{ cursor: "pointer", fontFamily: 'Poppins', fontSize: '16px', fontWeight: '600', marginLeft: '20px', marginRight: '20px', color: '#DFE0ED' }}>BLOGS</p>
                        <p onClick={() => {
                            navigate("/news");
                        }} style={{ cursor: "pointer", fontFamily: 'Poppins', fontSize: '16px', fontWeight: '600', marginLeft: '20px', marginRight: '20px', color: '#DFE0ED' }}>NEWS</p>
                        <div onMouseEnter={handleGiftNiftyMouseEnter} onMouseLeave={handleGiftNiftyMouseLeave} style={{ position: 'relative' }}>
                            <p style={{ fontFamily: 'Poppins', fontSize: '16px', fontWeight: '600', color: '#DFE0ED', marginLeft: '20px', marginRight: '20px', cursor: 'pointer', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>GIFT NIFTY <FaChevronDown style={{ marginLeft: '10px' }} /></p>
                            {isGiftNiftyOpen && (
                                <div style={{
                                    position: 'absolute',
                                    backgroundColor: '#181A23',
                                    borderRadius: '5px',
                                    padding: '10px',
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                                    zIndex: 100,
                                    width: '300px'
                                }}>
                                    <p
  style={{
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: '600',
    color: '#DFE0ED',
    margin: '20px',
    cursor: 'pointer',
  }}
>
  <a
    href="https://insider.giftnifty.com.in/blog/what-is-gift-nifty/"
    target="_blank"
    rel="dofollow"
    style={{ color: '#DFE0ED', textDecoration: 'none' }}
  >
    What is GIFT NIFTY?
  </a>
</p>

<p
  style={{
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: '600',
    color: '#DFE0ED',
    margin: '20px',
    cursor: 'pointer',
  }}
>
  <a
    href="https://insider.giftnifty.com.in/blog/gift-nifty-vs-sgx-nifty/"
    target="_blank"
    rel="dofollow"
    style={{ color: '#DFE0ED', textDecoration: 'none' }}
  >
    GIFT NIFTY VS SGX NIFTY
  </a>
</p>

<p
  style={{
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: '600',
    color: '#DFE0ED',
    margin: '20px',
    cursor: 'pointer',
  }}
>
  <a
    href="https://insider.giftnifty.com.in/blog/gift-nifty-vs-nifty-50/"
    target="_blank"
    rel="dofollow"
    style={{ color: '#DFE0ED', textDecoration: 'none' }}
  >
    GIFT NIFTY VS NIFTY 50
  </a>
</p>

<p
  style={{
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: '600',
    color: '#DFE0ED',
    margin: '20px',
    cursor: 'pointer',
  }}
>
  <a
    href="https://insider.giftnifty.com.in/blog/gift-nifty-timings/"
    target="_blank"
    rel="dofollow"
    style={{ color: '#DFE0ED', textDecoration: 'none' }}
  >
    GIFT NIFTY Timing
  </a>
</p>
</div>
                            )}
                        </div>
                        <FaSearch onClick={() => {
                            navigate("/search");
                        }} size={20} style={{ color: '#8C52FF', marginLeft: '20px', marginRight: '20px' }} />
                    </div>
                </div>
            </div>
            <div className="main-axis" style={{ width: '1341px', borderRadius: '20px', flexDirection: 'column', paddingBottom: '30px' }}>
                {/* Latest Blog Section */}
                {latestBlog && (
                    <div className="latest-blog" onClick={() => openBlogInNewTab(latestBlog.link)} style={{
                        display: 'flex',
                        borderRadius: '20px',
                        margin: '20px',
                        padding: '20px',
                        alignItems: 'center',
                        cursor: 'pointer'
                    }}>
                        <img className="latest-blog-image" alt="Latest Blog" src={latestBlog.thumbnail} style={{ width: '50%', borderRadius: '10px', marginRight: '20px' }} />
                        <div className="latest-blog-details">
                            <div className="blog-item-category-box">
                                <p className="blog-item-category">{getTagName(latestBlog.tag)}</p>
                            </div>
                            <p className="blog-item-title" style={{ fontSize: '35px', fontWeight: '600', marginBottom: '10px', marginTop: '20px', color: '#DFE0ED', lineHeight: 'normal', letterSpacing: 'normal' }}>{latestBlog.title}</p>
                            <div className="blog-item-description"
                                dangerouslySetInnerHTML={{ __html: latestBlog.description }}
                                style={{ fontSize: '20px', marginBottom: '20px', color: '#DFE0ED', lineHeight: 'normal', letterSpacing: 'normal' }}
                            />
                            <div className="blog-item-date" style={{ color: '#B0B3C3', fontSize: '16px' }}>{new Date(latestBlog.createdAt).toDateString()}</div>
                        </div>
                    </div>
                )}

                {/* Other Blogs Section */}
                <div style={{
                    marginLeft: '34px',
                    marginRight: '34px',
                    marginTop: '20px',
                    display: 'grid',
                    gridTemplateColumns: 'repeat(3, 1fr)',
                    gap: '20px'
                }}>
                    {otherBlogs.slice(0, 9).map(blog => (
                        <div key={blog.id} className="blog-item" onClick={() => openBlogInNewTab(blog.link)} style={{ cursor: 'pointer' }}>
                            <img className="blog-item-image" alt="Blog" src={blog.thumbnail} style={{ width: '100%', height: 'auto', borderRadius: '10px' }} />
                            <div className="blog-item-details">
                                <div className="blog-item-details-internal">
                                    <div className="blog-item-category-box">
                                        <p className="blog-item-category">{getTagName(blog.tag)}</p>
                                    </div>
                                    <p className="blog-item-title">{blog.title}</p>
                                    <div className="blog-item-description"
                                        dangerouslySetInnerHTML={{ __html: blog.description }}
                                        style={{ lineHeight: 'normal', letterSpacing: 'normal' }}
                                    />
                                </div>
                                <div className="blog-item-date">{new Date(blog.createdAt).toDateString()}</div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="view-all-button" onClick={() => {
                    openBlogInNewTab('https://insider.giftnifty.com.in/category/blog/');
                }} style={{ cursor: "pointer", padding: '10px 20px', borderRadius: '5px', backgroundColor: '#8C52FF' }}>
                    <p className="view-all-button-text" style={{ color: '#FFFFFF', fontSize: '16px', fontWeight: '600' }}>View All</p>
                </div>
            </div>

            <div style={{ marginTop: '30px', backgroundColor: '#181A23', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', display: 'flex', width: '100%' }}>
            <div style={{ width: '1341px', marginTop: '30px', alignItems: 'center', flexDirection: 'row', justifyContent: 'center', display: 'flex', }}>
                <img className="logo-white" alt="Logo white" src={require("./assets/logo-white.png")} style={{ cursor: 'pointer' }} onClick={() => {
                    navigate("/");
                }} />
                <div style={{ marginTop: '30px', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', display: 'flex', }} >
                    <div style={{ marginTop: '30px', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', display: 'flex', }} >
                        <p style={{
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontWeight: '600',
                            marginLeft: '20px',
                            marginRight: '20px',
                            color: '#DFE0ED',
                            textTransform: 'uppercase', cursor: "pointer"
                        }} onClick={() => {
                            navigate(`/about`);
                        }} >About</p>
                        <p style={{
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontWeight: '600',
                            marginLeft: '20px',
                            marginRight: '20px',
                            color: '#DFE0ED',
                            textTransform: 'uppercase', cursor: "pointer"
                        }} onClick={() => {
                            navigate(`/contact-us`);
                        }} >Contact Us</p>
                        <p style={{
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontWeight: '600',
                            marginLeft: '20px',
                            marginRight: '20px',
                            color: '#DFE0ED',
                            textTransform: 'uppercase', cursor: "pointer"
                        }} onClick={() => {
                            navigate(`/terms-and-conditions`);
                        }} >Terms and Conditions</p>
                        <p style={{
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontWeight: '600',
                            marginLeft: '20px',
                            marginRight: '20px',
                            color: '#DFE0ED',
                            textTransform: 'uppercase', cursor: "pointer"
                        }} onClick={() => {
                            navigate(`/privacy-policy`);
                        }} >Privacy Policy</p>
                        <p style={{
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontWeight: '600',
                            color: '#DFE0ED',
                            marginLeft: '20px',
                            marginRight: '20px',
                            textTransform: 'uppercase', cursor: "pointer"
                        }} onClick={() => {
                            navigate(`/disclaimer`);
                        }} >Disclaimer</p>
                    </div>
                    <p style={{
                        fontFamily: 'Poppins',
                        fontSize: '12px',
                        color: '#DFE0ED',
                        textAlign: 'center',
                        margin: '20px',
                        lineHeight: 'normal',
                        PaddingRight: '150px',
                        PaddingLeft: '150px'
                    }}>This website is solely for stock market information purposes and is not affiliated with any official entities such as SGX, NSE, NSEIX, IFSC, GIFT City, or Nifty. It does not act as a financial advisor or influencer, and we do not offer trading or investment advice, tips,
                        recommendations, or strategies through our website, social media channels, or any other
                        medium.<br /> <br />All users and members of this website are subject to our Disclaimer, Disclosure, Privacy
                        Policy, and Terms & Conditions. By using this website, you acknowledge and agree to all of the above terms and conditions.
                    </p>
                    <p style={{
                        fontFamily: 'Poppins',
                        fontSize: '16px',
                        fontWeight: '600',
                        color: '#DFE0ED',
                        margin: '20px',
                        cursor: 'pointer'
                    }} onClick={() => {
                        navigate("/");
                    }} >© 2024 GIFTNIFTY</p>
                </div>
            </div>
        </div>
        </div>
    );
}

export default AllBlogs;
