import '../App.css';
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet"; // Import Helmet for managing document head
import { useNavigate } from "react-router-dom";
import { FaSearch, FaChevronDown } from "react-icons/fa";

const AllNewsMobile = () => {
    const [blogs, setBlogs] = useState([]);
    const [tags, setTags] = useState([]); // State for storing tags
    const [containerWidth, setContainerWidth] = useState(window.innerWidth - 40);
    const navigate = useNavigate();
    const [isGiftNiftyOpen, setGiftNiftyOpen] = useState(false);


    useEffect(() => {
        // Update width on window resize
        const handleResize = () => {
            setContainerWidth(window.innerWidth - 40); // Adjust for padding
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleGiftNiftyToggle = () => {
        setGiftNiftyOpen(prev => !prev);
    };

    const handleGiftNiftyMouseEnter = () => {
        setGiftNiftyOpen(true);
    };

    const handleGiftNiftyMouseLeave = () => {
        setGiftNiftyOpen(false);
    };

    useEffect(() => {
        // Fetch tags
        fetch('https://insider.giftnifty.com.in/wp-json/wp/v2/tags')
            .then(response => response.json())
            .then(tagData => {
                const formattedTags = tagData.map(tag => ({
                    id: tag.id,
                    name: tag.name
                }));
                setTags(formattedTags);
            });

        // Fetch blog posts
        fetch('https://insider.giftnifty.com.in/wp-json/wp/v2/posts')
            .then(response => response.json())
            .then(data => {
                const formattedBlogs = data
                    .filter(blog => blog.categories.includes(1)) // Filter blogs with category ID 2
                    .map(blog => ({
                        id: blog.id,
                        title: blog.title.rendered,
                        description: blog.excerpt.rendered,
                        thumbnail: blog.uagb_featured_image_src["2048x2048"][0],
                        createdAt: blog.date,
                        tag: blog.tags[0], // First tag ID (we will map this to a tag name)
                        link: blog.link, // Blog link for opening in a new tab
                    }));
                setBlogs(formattedBlogs);
            });
    }, []);

    // Helper function to get tag name by ID
    const getTagName = (tagId) => {
        const tag = tags.find(t => t.id === tagId);
        return tag ? tag.name : 'No Tag';
    };

    const otherBlogs = blogs;

    const openBlogInNewTab = (url) => {
        window.open(url, '_blank', 'noopener,noreferrer'); // Opens the blog in a new tab
    };

    return (
        <div className="desktop" style={{ height: blogs.length > 0 ? null : '100vh' }}>
            <div
                style={{
                    height: '90px',
                    backgroundColor: '#181A23',
                    display: 'flex',
                    flexDirection: 'column', // Stack items vertically
                    alignItems: 'center', // Center items horizontally
                    justifyContent: 'center', // Center items vertically
                    width: '100%',
                    position: 'sticky',
                    top: 0,
                    zIndex: 1000,
                    padding: '10px 20px', // Add padding for left/right
                }}
            >
                <img
                    className="logo-white"
                    alt="Logo white"
                    src={require("../assets/logo-white.png")}
                    style={{ cursor: 'pointer', width: '150px', height: 'auto' }} // Adjust logo size
                    onClick={() => {
                        navigate("/mobile");
                    }}
                />
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-around', // Space out navigation items
                        flexWrap: 'wrap', // Allow wrapping
                        gap: '15px', // Use gap for spacing between elements
                        marginTop: '10px', // Add some margin above the nav items
                        width: '100%', // Full width for nav items
                    }}
                >
                    <p
                        onClick={() => {
                            navigate("/mobile/blogs");
                        }}
                        style={{
                            cursor: "pointer",
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontWeight: '600',
                            color: '#DFE0ED',
                            margin: '0', // Remove margin
                        }}
                    >
                        BLOGS
                    </p>
                    <p
                        onClick={() => {
                            navigate("/mobile/news");
                        }}
                        style={{
                            cursor: "pointer",
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontWeight: '600',
                            color: '#DFE0ED',
                            margin: '0', // Remove margin
                        }}
                    >
                        NEWS
                    </p>
                    <div
    onMouseEnter={handleGiftNiftyMouseEnter}
    onMouseLeave={handleGiftNiftyMouseLeave}
    style={{ position: 'relative' }}
>
    <p
        style={{
            fontFamily: 'Poppins',
            fontSize: '16px',
            fontWeight: '600',
            color: '#DFE0ED',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            margin: '0', // Remove margin
        }}
    >
        GIFT NIFTY <FaChevronDown style={{ marginLeft: '10px' }} />
    </p>
    {isGiftNiftyOpen && (
        <div
            style={{
                position: 'absolute',
                backgroundColor: '#181A23',
                borderRadius: '5px',
                padding: '10px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                zIndex: 100,
                width: '200px', // Adjust width for mobile
            }}
        >
            {[
                { text: "What is GIFT NIFTY?", link: "https://insider.giftnifty.com.in/blog/what-is-gift-nifty/" },
                { text: "GIFT NIFTY VS SGX NIFTY", link: "https://insider.giftnifty.com.in/blog/gift-nifty-vs-sgx-nifty/" },
                { text: "GIFT NIFTY VS NIFTY 50", link: "https://insider.giftnifty.com.in/blog/gift-nifty-vs-nifty-50/" },
                { text: "GIFT NIFTY Timing", link: "https://insider.giftnifty.com.in/blog/gift-nifty-timings/" },
            ].map((item, index) => (
                <a
                    key={index}
                    href={item.link}
                    target="_blank"
                    style={{
                        fontFamily: 'Poppins',
                        fontSize: '16px',
                        fontWeight: '600',
                        color: '#DFE0ED',
                        margin: '10px 0', // Adjust margin for mobile
                        cursor: 'pointer',
                        display: 'block', // Ensure each link is displayed in a block
                    }}
                >
                    {item.text}
                </a>
            ))}
        </div>
    )}
</div>
                    <FaSearch
                        onClick={() => {
                            navigate("/mobile/search");
                        }}
                        size={20}
                        style={{ color: '#8C52FF' }} // Removed margin for icon
                    />
                </div>
            </div>
            <div className="main-axis" style={{
                flexDirection: 'column',
                display: 'flex',
                width: `${containerWidth}px`, // Set the width based on the state
                flexDirection: 'column',
                flexWrap: 'nowrap',
            }}>
                {otherBlogs.slice(0, 9).map(blog => (
                    <div key={blog.id} className="blog-item" onClick={() => openBlogInNewTab(blog.link)} style={{ cursor: 'pointer' }}>
                        <img className="blog-item-image" alt="Blog" src={blog.thumbnail} style={{ width: '100%', height: 'auto', borderRadius: '10px' }} />
                        <div className="blog-item-details">
                            <div className="blog-item-details-internal">
                                <div className="blog-item-category-box">
                                    <p className="blog-item-category">{getTagName(blog.tag)}</p>
                                </div>
                                <p className="blog-item-title">{blog.title}</p>
                                <div className="blog-item-description"
                                    dangerouslySetInnerHTML={{ __html: blog.description }}
                                    style={{ lineHeight: 'normal', letterSpacing: 'normal' }}
                                />
                            </div>
                            <div className="blog-item-date">{new Date(blog.createdAt).toDateString()}</div>
                        </div>
                    </div>
                ))}

                <div className="view-all-button" onClick={() => {
                    openBlogInNewTab('https://insider.giftnifty.com.in/category/news/');
                }} style={{ cursor: "pointer", padding: '10px 20px', borderRadius: '5px', backgroundColor: '#8C52FF' }}>
                    <p className="view-all-button-text" style={{ color: '#FFFFFF', fontSize: '16px', fontWeight: '600' }}>View All</p>
                </div>
            </div>
            <div style={{ marginTop: '30px', backgroundColor: '#181A23', display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                <div style={{ width: '100%', maxWidth: '1341px', marginTop: '30px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <img
                        className="logo-white"
                        alt="Logo white"
                        src={require("../assets/logo-white.png")}
                        style={{ cursor: 'pointer', width: '100%', maxWidth: '210px' }}
                        onClick={() => navigate("/mobile")}
                    />
                    <div style={{ marginTop: '30px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <p style={{
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontWeight: '600',
                            color: '#DFE0ED',
                            margin: '10px',
                            textTransform: 'uppercase',
                            cursor: "pointer"
                        }} onClick={() => navigate(`/mobile/about`)}>About</p>
                        <p style={{
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontWeight: '600',
                            color: '#DFE0ED',
                            margin: '10px',
                            textTransform: 'uppercase',
                            cursor: "pointer"
                        }} onClick={() => navigate(`/mobile/contact-us`)}>Contact Us</p>
                        <p style={{
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontWeight: '600',
                            color: '#DFE0ED',
                            margin: '10px',
                            textTransform: 'uppercase',
                            cursor: "pointer"
                        }} onClick={() => {
                            navigate(`/mobile/terms-and-conditions`);
                        }} >Terms and Conditions</p>
                        <p style={{
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontWeight: '600',
                            color: '#DFE0ED',
                            margin: '10px',
                            textTransform: 'uppercase',
                            cursor: "pointer"
                        }} onClick={() => {
                            navigate(`/mobile/privacy-policy`);
                        }} >Privacy Policy</p>
                        <p style={{
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontWeight: '600',
                            color: '#DFE0ED',
                            margin: '10px',
                            textTransform: 'uppercase',
                            cursor: "pointer"
                        }} onClick={() => navigate(`/mobile/disclaimer`)}>Disclaimer</p>
                        <p style={{
                            fontFamily: 'Poppins',
                            fontSize: '12px',
                            color: '#DFE0ED',
                            textAlign: 'center',
                            margin: '20px',
                            lineHeight: 'normal'
                        }}>This website is solely for stock market information purposes and is not affiliated with any official entities such as SGX, NSE, NSEIX, IFSC, GIFT City, or Nifty. It does not act as a financial advisor or influencer, and we do not offer trading or investment advice, tips,
                            recommendations, or strategies through our website, social media channels, or any other
                            medium.<br /><br />All users and members of this website are subject to our Disclaimer, Disclosure, Privacy
                            Policy, and Terms & Conditions. By using this website, you acknowledge and agree to all of the above terms and conditions.
                        </p>
                        <p style={{
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontWeight: '600',
                            color: '#DFE0ED',
                            margin: '20px',
                            cursor: 'pointer'
                        }} onClick={() => navigate("/mobile")}>© 2024 GIFTNIFTY</p>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default AllNewsMobile;