// CurrentTime.js
import React, { useState, useEffect } from 'react';

const CurrentTime = () => {
    // State to store the current time
    const [currentTime, setCurrentTime] = useState(new Date());

    // useEffect to update the time every second
    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 2000); // Update every second

        // Cleanup interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    // Format time as a string (HH:MM:SS)
    const formattedTime = currentTime.toLocaleString();

    return (
        <p className="stat-item-title" style={{ margin: '0px', padding: '0px' }}>
            Last Updated on {formattedTime}
        </p>
    );
};

export default CurrentTime;