import './App.css';
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet"; // Import Helmet for managing document head
import { FaSearch } from "react-icons/fa";
import { FaChevronDown } from "react-icons/fa";

const ContactUs = () => {
  const navigate = useNavigate();
  const [isGiftNiftyOpen, setGiftNiftyOpen] = useState(false);

  const handleGiftNiftyToggle = () => {
    setGiftNiftyOpen(prev => !prev); // Toggle dropdown
  };

  const handleGiftNiftyMouseEnter = () => {
    setGiftNiftyOpen(true); // Open dropdown on hover
  };

  const handleGiftNiftyMouseLeave = () => {
    setGiftNiftyOpen(false); // Close dropdown on mouse leave
  };

  const handleClick = () => {
    navigate("/all-blogs");
  };

  return (
    <div className="desktop">
      <Helmet>
        <title>Contact Us - GiftNifty.com.in</title>

        {/* Primary Meta Tags */}
        <meta name="description" content="Get in touch with GiftNifty.com.in. Reach out for any inquiries or support regarding GIFT Nifty index prices, charts, and trading updates." />
        <meta name="keywords" content="Contact Us, Gift Nifty, GIFT Nifty India, GIFT Nifty Support, GIFT Nifty Trading, Contact Gift Nifty" />
        <meta name="author" content="Gift Nifty" />

        {/* Open Graph / Facebook Meta Tags */}
        <meta property="og:title" content="Contact Us - GiftNifty.com.in" />
        <meta property="og:description" content="Reach out to GiftNifty.com.in for any inquiries or support related to GIFT Nifty index prices, charts, and updates." />
        <meta property="og:url" content="https://giftnifty.com.in/contact-us" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="./assets/logo-white.png" />

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Contact Us - GiftNifty.com.in" />
        <meta name="twitter:description" content="Contact GiftNifty.com.in for any questions or support related to GIFT Nifty prices and trading." />
        <meta name="twitter:image" content="./assets/logo-white.png" />

        <link rel="apple-touch-icon" href="%PUBLIC_URL%/logo192.png" />
        <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />
      </Helmet>
      <div style={{
        height: '90px',
        backgroundColor: '#181A23',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        display: 'flex',
        width: '100%',
        position: 'sticky',
        top: 0,
        zIndex: 1000
      }}>
        <div style={{ width: '1341px', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between', display: 'flex' }}>
          <img className="logo-white" alt="Logo white" src={require("./assets/logo-white.png")} style={{ cursor: 'pointer' }} onClick={() => {
            navigate("/");
          }} />
          <div style={{ alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between', display: 'flex' }}>
            <p onClick={() => {
              navigate("/blogs");
            }} style={{ cursor: "pointer", fontFamily: 'Poppins', fontSize: '16px', fontWeight: '600', marginLeft: '20px', marginRight: '20px', color: '#DFE0ED' }}>BLOGS</p>
            <p onClick={() => {
              navigate("/news");
            }} style={{ cursor: "pointer", fontFamily: 'Poppins', fontSize: '16px', fontWeight: '600', marginLeft: '20px', marginRight: '20px', color: '#DFE0ED' }}>NEWS</p>
            <div onMouseEnter={handleGiftNiftyMouseEnter} onMouseLeave={handleGiftNiftyMouseLeave} style={{ position: 'relative' }}>
              <p style={{ fontFamily: 'Poppins', fontSize: '16px', fontWeight: '600', color: '#DFE0ED', marginLeft: '20px', marginRight: '20px', cursor: 'pointer', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>GIFT NIFTY <FaChevronDown style={{ marginLeft: '10px' }} /></p>
              {isGiftNiftyOpen && (
                <div style={{
                  position: 'absolute',
                  backgroundColor: '#181A23',
                  borderRadius: '5px',
                  padding: '10px',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                  zIndex: 100,
                  width: '300px'
                }}>
                  <p
                    style={{
                      fontFamily: 'Poppins',
                      fontSize: '16px',
                      fontWeight: '600',
                      color: '#DFE0ED',
                      margin: '20px',
                      cursor: 'pointer',
                    }}
                  >
                    <a
                      href="https://insider.giftnifty.com.in/blog/what-is-gift-nifty/"
                      target="_blank"
                      rel="dofollow"
                      style={{ color: '#DFE0ED', textDecoration: 'none' }}
                    >
                      What is GIFT NIFTY?
                    </a>
                  </p>

                  <p
                    style={{
                      fontFamily: 'Poppins',
                      fontSize: '16px',
                      fontWeight: '600',
                      color: '#DFE0ED',
                      margin: '20px',
                      cursor: 'pointer',
                    }}
                  >
                    <a
                      href="https://insider.giftnifty.com.in/blog/gift-nifty-vs-sgx-nifty/"
                      target="_blank"
                      rel="dofollow"
                      style={{ color: '#DFE0ED', textDecoration: 'none' }}
                    >
                      GIFT NIFTY VS SGX NIFTY
                    </a>
                  </p>

                  <p
                    style={{
                      fontFamily: 'Poppins',
                      fontSize: '16px',
                      fontWeight: '600',
                      color: '#DFE0ED',
                      margin: '20px',
                      cursor: 'pointer',
                    }}
                  >
                    <a
                      href="https://insider.giftnifty.com.in/blog/gift-nifty-vs-nifty-50/"
                      target="_blank"
                      rel="dofollow"
                      style={{ color: '#DFE0ED', textDecoration: 'none' }}
                    >
                      GIFT NIFTY VS NIFTY 50
                    </a>
                  </p>

                  <p
                    style={{
                      fontFamily: 'Poppins',
                      fontSize: '16px',
                      fontWeight: '600',
                      color: '#DFE0ED',
                      margin: '20px',
                      cursor: 'pointer',
                    }}
                  >
                    <a
                      href="https://insider.giftnifty.com.in/blog/gift-nifty-timings/"
                      target="_blank"
                      rel="dofollow"
                      style={{ color: '#DFE0ED', textDecoration: 'none' }}
                    >
                      GIFT NIFTY Timing
                    </a>
                  </p>
                </div>
              )}
            </div>
            <FaSearch onClick={() => {
              navigate("/search");
            }} size={20} style={{ color: '#8C52FF', marginLeft: '20px', marginRight: '20px' }} />
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div className="main-axis" style={{ width: '1341px', borderRadius: '20px', flexDirection: 'column', paddingBottom: '30px' }}>
          <div className="main-axis" style={{ flexDirection: 'column', display: 'flex' }}>
            <h5 className="content-title">Contact Us</h5>
            <p className="about-text" style={{ lineHeight: 'normal', width: '100%', fontSize: '18px', color: 'white' }}>
              At GIFTNifty.com.in, we highly value your feedback and are always ready to assist with any queries or concerns you might have. Whether you need help navigating our platform, understanding live GIFT Nifty data, or just general inquiries, our dedicated team is here to support you.<br /><br />
              <span className="about-text-bold">Get in Touch</span><br /><br />
              For general inquiries, feedback, or support, feel free to contact us at: <span className="about-text-bold">contact@giftnifty.com.in.</span>
              <br />
              <br />
              <span className="about-text-bold">Why Contact Us?</span>
              <br />
              <br />
              <span className="about-text-bold">● Customer Support:</span>Our support team is ready to assist you with any issues, whether it’s related to platform navigation or understanding live GIFT Nifty data.<br />
              <br />
              <span className="about-text-bold">● Feedback:</span>Your feedback helps us improve our services. We want to know what you love about GIFTNifty.com.in and where we can improve.<br /><br />
              <span className="about-text-bold">● Partnerships & Collaborations:</span> Interested in partnering with us? We’re open to collaboration ideas.
              <br /><br />
              <span className="about-text-bold">● Media Inquiries:</span> For press and media-related inquiries, reach out to us for detailed information.<br /><br />
              <br />
              <span className="about-text-bold">Response Time</span><br /><br />
              We strive to respond to all inquiries within 24 hours. However, during peak times, responses may take a bit longer. Rest assured, we value your query and will address it as soon as possible.<br /><br />Thank you for choosing GIFTNifty.com.in! We look forward to assisting you and making your trading experience smooth and informed.
            </p>
          </div>
        </div>
      </div>
      <div style={{ marginTop: '30px', backgroundColor: '#181A23', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', display: 'flex', width: '100%' }}>
        <div style={{ width: '1341px', marginTop: '30px', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', display: 'flex', }}>
          <img className="logo-white" alt="Logo white" src={require("./assets/logo-white.png")} style={{ cursor: 'pointer' }} onClick={() => {
            navigate("/");
          }} />
          <div style={{ marginTop: '30px', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', display: 'flex', }} >
            <div style={{ marginTop: '30px', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', display: 'flex', }} >
              <p style={{
                fontFamily: 'Poppins',
                fontSize: '16px',
                fontWeight: '600',
                marginLeft: '20px',
                marginRight: '20px',
                color: '#DFE0ED',
                textTransform: 'uppercase', cursor: "pointer"
              }} onClick={() => {
                navigate(`/about`);
              }} >About</p>
              <p style={{
                fontFamily: 'Poppins',
                fontSize: '16px',
                fontWeight: '600',
                marginLeft: '20px',
                marginRight: '20px',
                color: '#DFE0ED',
                textTransform: 'uppercase', cursor: "pointer"
              }} onClick={() => {
                navigate(`/contact-us`);
              }} >Contact Us</p>
              <p style={{
                fontFamily: 'Poppins',
                fontSize: '16px',
                fontWeight: '600',
                marginLeft: '20px',
                marginRight: '20px',
                color: '#DFE0ED',
                textTransform: 'uppercase', cursor: "pointer"
              }} onClick={() => {
                navigate(`/terms-and-conditions`);
              }} >Terms and Conditions</p>
              <p style={{
                fontFamily: 'Poppins',
                fontSize: '16px',
                fontWeight: '600',
                marginLeft: '20px',
                marginRight: '20px',
                color: '#DFE0ED',
                textTransform: 'uppercase', cursor: "pointer"
              }} onClick={() => {
                navigate(`/privacy-policy`);
              }} >Privacy Policy</p>
              <p style={{
                fontFamily: 'Poppins',
                fontSize: '16px',
                fontWeight: '600',
                color: '#DFE0ED',
                marginLeft: '20px',
                marginRight: '20px',
                textTransform: 'uppercase', cursor: "pointer"
              }} onClick={() => {
                navigate(`/disclaimer`);
              }} >Disclaimer</p>
            </div>
            <p style={{
              fontFamily: 'Poppins',
              fontSize: '12px',
              color: '#DFE0ED',
              textAlign: 'center',
              margin: '20px',
              lineHeight: 'normal',
              paddingLeft: '150px',
              paddingRight: '150px'
            }}>This website is solely for stock market information purposes and is not affiliated with any official entities such as SGX, NSE, NSEIX, IFSC, GIFT City, or Nifty. It does not act as a financial advisor or influencer, and we do not offer trading or investment advice, tips,
              recommendations, or strategies through our website, social media channels, or any other
              medium.<br /> <br />All users and members of this website are subject to our Disclaimer, Disclosure, Privacy
              Policy, and Terms & Conditions. By using this website, you acknowledge and agree to all of the above terms and conditions.
            </p>
            <p style={{
              fontFamily: 'Poppins',
              fontSize: '16px',
              fontWeight: '600',
              color: '#DFE0ED',
              margin: '20px',
              cursor: 'pointer'
            }} onClick={() => {
              navigate("/");
            }} >© 2024 GIFTNIFTY</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;