import '../App.css';
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet"; // Import Helmet for managing document head
import { FaSearch } from "react-icons/fa";
import { FaChevronDown } from "react-icons/fa";

const PrivacyPolicyMobile = () => {
    const navigate = useNavigate();
    const [isGiftNiftyOpen, setGiftNiftyOpen] = useState(false);
    const [containerWidth, setContainerWidth] = useState(window.innerWidth - 40);

    useEffect(() => {
        // Update width on window resize
        const handleResize = () => {
            setContainerWidth(window.innerWidth - 40); // Adjust for padding
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleGiftNiftyToggle = () => {
        setGiftNiftyOpen(prev => !prev); // Toggle dropdown
    };

    const handleGiftNiftyMouseEnter = () => {
        setGiftNiftyOpen(true); // Open dropdown on hover
    };

    const handleGiftNiftyMouseLeave = () => {
        setGiftNiftyOpen(false); // Close dropdown on mouse leave
    };

    const handleClick = () => {
        navigate("/blogs");
    };

    return (
        <div className="desktop">
            <div
                style={{
                    height: '90px',
                    backgroundColor: '#181A23',
                    display: 'flex',
                    flexDirection: 'column', // Stack items vertically
                    alignItems: 'center', // Center items horizontally
                    justifyContent: 'center', // Center items vertically
                    width: '100%',
                    position: 'sticky',
                    top: 0,
                    zIndex: 1000,
                    padding: '10px 20px', // Add padding for left/right
                }}
            >
                <img
                    className="logo-white"
                    alt="Logo white"
                    src={require("../assets/logo-white.png")}
                    style={{ cursor: 'pointer', width: '150px', height: 'auto' }} // Adjust logo size
                    onClick={() => {
                        navigate("/mobile");
                    }}
                />
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-around', // Space out navigation items
                        flexWrap: 'wrap', // Allow wrapping
                        gap: '15px', // Use gap for spacing between elements
                        marginTop: '10px', // Add some margin above the nav items
                        width: '100%', // Full width for nav items
                    }}
                >
                    <p
                        onClick={() => {
                            navigate("/mobile/blogs");
                        }}
                        style={{
                            cursor: "pointer",
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontWeight: '600',
                            color: '#DFE0ED',
                            margin: '0', // Remove margin
                        }}
                    >
                        BLOGS
                    </p>
                    <p
                        onClick={() => {
                            navigate("/mobile/news");
                        }}
                        style={{
                            cursor: "pointer",
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontWeight: '600',
                            color: '#DFE0ED',
                            margin: '0', // Remove margin
                        }}
                    >
                        NEWS
                    </p>
                    <div
    onMouseEnter={handleGiftNiftyMouseEnter}
    onMouseLeave={handleGiftNiftyMouseLeave}
    style={{ position: 'relative' }}
>
    <p
        style={{
            fontFamily: 'Poppins',
            fontSize: '16px',
            fontWeight: '600',
            color: '#DFE0ED',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            margin: '0', // Remove margin
        }}
    >
        GIFT NIFTY <FaChevronDown style={{ marginLeft: '10px' }} />
    </p>
    {isGiftNiftyOpen && (
        <div
            style={{
                position: 'absolute',
                backgroundColor: '#181A23',
                borderRadius: '5px',
                padding: '10px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                zIndex: 100,
                width: '200px', // Adjust width for mobile
            }}
        >
            {[
                { text: "What is GIFT NIFTY?", link: "https://insider.giftnifty.com.in/blog/what-is-gift-nifty/" },
                { text: "GIFT NIFTY VS SGX NIFTY", link: "https://insider.giftnifty.com.in/blog/gift-nifty-vs-sgx-nifty/" },
                { text: "GIFT NIFTY VS NIFTY 50", link: "https://insider.giftnifty.com.in/blog/gift-nifty-vs-nifty-50/" },
                { text: "GIFT NIFTY Timing", link: "https://insider.giftnifty.com.in/blog/gift-nifty-timings/" },
            ].map((item, index) => (
                <a
                    key={index}
                    href={item.link}
                    target="_blank"
                    style={{
                        fontFamily: 'Poppins',
                        fontSize: '16px',
                        fontWeight: '600',
                        color: '#DFE0ED',
                        margin: '10px 0', // Adjust margin for mobile
                        cursor: 'pointer',
                        display: 'block', // Ensure each link is displayed in a block
                    }}
                >
                    {item.text}
                </a>
            ))}
        </div>
    )}
</div>
                    <FaSearch
                        onClick={() => {
                            navigate("/mobile/search");
                        }}
                        size={20}
                        style={{ color: '#8C52FF' }} // Removed margin for icon
                    />
                </div>
            </div>
            <div style={{
                display: 'flex', flexDirection: 'column',
            }}>
                <div className="main-axis" style={{
                    display: 'flex',
                    width: `${containerWidth}px`, // Set the width based on the state
                    flexDirection: 'column',
                    flexWrap: 'nowrap',
                    justifyContent: 'space-between', // Space out items evenly
                }}> <p className="about-text" style={{
                    marginLeft: '0px',
                    marginRight: '0px',
                    lineHeight: 'normal', width: '100%', fontSize: '18px', color: 'white'
                }}>
                        <span className="about-text-bold">Privacy Policy</span><br />
                        <br />GIFT NIFTY reserves the right to modify this Privacy Policy from time to time without notice.
                        <br />You are responsible for checking this privacy policy periodically for changes. If you
                        continue to use the GIFT NIFTY Services after we post changes to this Privacy Policy, you
                        are signifying your acceptance of the new privacy policy.
                        <br />Any such change, update, or modification to this privacy policy will be effective
                        immediately upon posting on the Site.
                        <br />At GIFT NIFTY, the privacy of our visitors is of extreme importance to us. This privacy policy
                        document outlines the types of personal information received and collected by GIFT NIFTY
                        and how it is used.
                        <br />This Privacy Policy explains the collection, use, and disclosure of Personal Information
                        through our website. “Personal Information” means information that would allow a party to contact you, including, for example, your full name, address, telephone number, or email address.
                        <br /><br /><span className="about-text-bold">Submitted & Personal Information</span>
                        <br />We do not collect any Personal Information about you unless you voluntarily provide it to
                        us.
                        <br />We collect Personal Information about you when you submit information or requests to us
                        (through this website or otherwise). We use your submitted information to send you emails that you have requested, to send your job application and resume to the applicable third party when you apply to certain jobs on our website, to improve your job search experience on our website, and for administrative purposes (such as notifying you about changes to this Privacy Policy). In emails we send you, we provide a method to unsubscribe from future emails.
                        <br />In order for you to access certain services and to purchase products that we offer via the Site, we may require you to provide us with certain information that personally identifies you (“Personal Information”).
                        <br />Personal Information includes the following categories of information:
                        <br />(1) <span className="about-text-bold">Contact Data</span> (such as your name, mailing address, and email address)
                        <br />(2) <span className="about-text-bold">Financial Data</span> (such as your credit card information)
                        <br />(3) <span className="about-text-bold">Demographic Data</span> (such as your zip code, age, and income)
                        <br />If you communicate with us by email, post messages to any of our chat groups, bulletin
                        boards, or forums, or otherwise complete online forms, surveys, or contest entries, any
                        information provided in such communication may be collected as Personal Information.
                        <br /><br /><span className="about-text-bold">Log Files</span>
                        <br />Like many other websites, GIFT NIFTY makes use of log files. The information inside the log files includes internet protocol (IP) addresses, type of browser, Internet Service Provider (ISP), date/time stamp, referring/exit pages, and number of clicks to analyze trends, administer the site, track user movement around the site, and gather demographic information. IP addresses and other such information are not linked to any information that is personally identifiable.
                        <br /><br /><span className="about-text-bold">Traffic Data</span>
                        <br />Traffic Data is anonymous information that does not personally identify you but is helpful
                        for marketing purposes or for improving your experience on the Site. We also use “cookies” to customize content specific to your interests, to ensure that you do not see the same advertisement repeatedly, and to store your password so you do not have to re-enter it each time you visit the Site. Our Site also automatically creates logs regarding your activity on our Site. These logs may identify the features that you use, the actions that you take, and the information that you access.
                        <br />We automatically track and collect the following categories of information when you visit our Site as examples:
                        <br />(1) IP addresses
                        <br />(2) Domain servers
                        <br />(3) Types of computers accessing the Site
                        <br />(4) Types of web browsers used to access the Site
                        <br />(5) The length of your visit and the pages that you viewed (collectively “Traffic Data”).
                        <br /><br /><span className="about-text-bold">Cookies and Web Beacons</span>
                        <br />GIFT NIFTY does use cookies to store information about visitors’ preferences, record
                        user-specific information on which pages the user accesses or visits, customize Web page content based on visitors’ browser type or other information that the visitor sends via their browser.
                        <br /><br /><span className="about-text-bold">Sharing or Transferring of Personal Information</span>
                        <br />By voluntarily registering and agreeing to (checked box opting into receiving marketing
                        during registration), you are giving us your express consent to share your Personal
                        Information with third parties in the ways described in this Privacy Policy.
                        <br />We may share Demographic Data with advertisers and other third parties.
                        <br />We may also share Contact Data with our business partners who assist us by performing
                        core services (such as hosting, billing, fulfillment, or data storage and
                        <br />security) or with third parties in connection with services that you have requested or authorized.
                        <br />We will use Personal Information to comply with applicable laws and regulations and may disclose such information when required by law or in response to a subpoena or search warrant.
                        <br />In addition, we may share Personal Information to investigate or remedy any violations of our policies or applicable laws.
                        <br /><br /><span className="about-text-bold">Third-Party Sites</span>
                        <br />GIFT NIFTY may contain links to other websites. GIFT NIFTY is not responsible for the privacy
                        practices or the content of such third-party websites.
                        <br />We encourage our users to be aware when they leave our site and to read the privacy
                        statements of each and every website that collects Personal Information.
                        <br />This Privacy Policy applies solely to information collected by GIFT NIFTY.
                        <br /><br /><span className="about-text-bold">Children’s Privacy</span>
                        <br />The GIFT NIFTY services are not directed to children under the age of 13. If you are a parent
                        or guardian and you are aware that your child has provided us with Personal Information,
                        please contact us.
                        <br />If we become aware that we have collected Personal Information from children without
                        verification of parental consent, we take steps to remove that information from our
                        servers.
                        <br /><br /><span className="about-text-bold">Security of Your Personal Information</span>
                        <br />GIFT NIFTY employs reasonable security measures to protect against the loss, misuse, or
                        alteration of Personal Information under our control.
                        <br />However, we cannot guarantee the security of any Personal Information you transmit to
                        us over the Internet or that we store, and you do so at your own risk.
                        <br />We cannot ensure or warrant the security of any information you transmit to us, and you
                        provide such information at your own risk.
                        <br /><br /><span className="about-text-bold">Contact Us</span>
                        <br />If you have any questions about this Privacy Policy or your Personal Information, please <span onClick={() => {
                            navigate(`/contact-us`);
                        }} style={{ color: 'blue', cursor: 'pointer' }}>contact us</span>.
                    </p>
                </div>
            </div>
            <div style={{ marginTop: '30px', backgroundColor: '#181A23', display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                <div style={{ width: '100%', maxWidth: '1341px', marginTop: '30px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <img
                        className="logo-white"
                        alt="Logo white"
                        src={require("../assets/logo-white.png")}
                        style={{ cursor: 'pointer', width: '100%', maxWidth: '210px' }}
                        onClick={() => navigate("/mobile")}
                    />
                    <div style={{ marginTop: '30px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <p style={{
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontWeight: '600',
                            color: '#DFE0ED',
                            margin: '10px',
                            textTransform: 'uppercase',
                            cursor: "pointer"
                        }} onClick={() => navigate(`/mobile/about`)}>About</p>
                        <p style={{
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontWeight: '600',
                            color: '#DFE0ED',
                            margin: '10px',
                            textTransform: 'uppercase',
                            cursor: "pointer"
                        }} onClick={() => navigate(`/mobile/contact-us`)}>Contact Us</p>
                        <p style={{
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontWeight: '600',
                            color: '#DFE0ED',
                            margin: '10px',
                            textTransform: 'uppercase',
                            cursor: "pointer"
                        }} onClick={() => {
                            navigate(`/mobile/terms-and-conditions`);
                        }} >Terms and Conditions</p>
                        <p style={{
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontWeight: '600',
                            color: '#DFE0ED',
                            margin: '10px',
                            textTransform: 'uppercase',
                            cursor: "pointer"
                        }} onClick={() => {
                            navigate(`/mobile/privacy-policy`);
                        }} >Privacy Policy</p>
                        <p style={{
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontWeight: '600',
                            color: '#DFE0ED',
                            margin: '10px',
                            textTransform: 'uppercase',
                            cursor: "pointer"
                        }} onClick={() => navigate(`/mobile/disclaimer`)}>Disclaimer</p>
                        <p style={{
                            fontFamily: 'Poppins',
                            fontSize: '12px',
                            color: '#DFE0ED',
                            textAlign: 'center',
                            margin: '20px',
                            lineHeight: 'normal'
                        }}>This website is solely for stock market information purposes and is not affiliated with any official entities such as SGX, NSE, NSEIX, IFSC, GIFT City, or Nifty. It does not act as a financial advisor or influencer, and we do not offer trading or investment advice, tips,
                            recommendations, or strategies through our website, social media channels, or any other
                            medium.<br /><br />All users and members of this website are subject to our Disclaimer, Disclosure, Privacy
                            Policy, and Terms & Conditions. By using this website, you acknowledge and agree to all of the above terms and conditions.
                        </p>
                        <p style={{
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontWeight: '600',
                            color: '#DFE0ED',
                            margin: '20px',
                            cursor: 'pointer'
                        }} onClick={() => navigate("/mobile")}>© 2024 GIFTNIFTY</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PrivacyPolicyMobile;