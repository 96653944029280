import '../App.css';
import Faq from "react-faq-component";
import React, { useEffect, useState } from "react";
import CandlestickChart from 'react-candlestick-chart';
import { Helmet } from "react-helmet"; // Import Helmet for managing document head
import { FaSearch } from "react-icons/fa";
import { FaChevronDown } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid } from 'recharts';
import FAQAccordion from '../FAQAccordion';
import CurrentTime from '../CurrentTime';

const data = [
    {
        title: "What Does GIFT Nifty Indicate?",
        content: `GIFT Nifty indicates the performance of the top 50 companies listed on the National Stock Exchange (NSE) in India. It serves as a benchmark for tracking market sentiment and movements in the Indian stock market. By reflecting the performance of these major companies, GIFT Nifty provides insights into the overall health and direction of the Indian equity market.`,
    },
    {
        title: "What is the Full Form of GIFT Nifty?",
        content:
            "The full form of GIFT Nifty is Gujarat International Finance Tec-City Nifty. The name reflects its association with the GIFT City in Gandhinagar, Gujarat, which is designed to be a global financial hub.",
    },
    {
        title: "What is the Difference Between Nifty 50 and GIFT Nifty?",
        content: `The key differene is that Nifty 50 is a benchmark index of the NSE, representing the performance of 50 large and liquid Indian companies whereas GIFT Nifty is a futures contract derivative of the Nifty 50 index, traded on the NSE International Exchange (NSE IX) in GIFT City, Gujarat. While Nifty 50 is the underlying index, GIFT Nifty provides a trading mechanism for investors to speculate on or hedge against movements in the Nifty 50 index.`,
    },
    {
        title: "Which Stocks are in GIFT Nifty?",
        content: `GIFT Nifty tracks the same 50 companies that are part of the Nifty 50 index. These are some of the largest and most liquid companies listed on the NSE. The specific stocks can be found on the NSE website or through financial news sources.`,
    },
    {
        title: "How Does GIFT Nifty Work?",
        content: `GIFT Nifty functions as a futures contract that derives its value from the Nifty 50 index. Traders buy and sell GIFT Nifty futures contracts to gain exposure to the Nifty 50 index’s performance. The contracts are traded in US dollars on the NSE International Exchange in GIFT City, providing a means for both domestic and international investors to speculate on or hedge against movements in the Indian market.`,
    },
    {
        title: "To Which Country Does the GIFT Nifty Index Belong?",
        content: `The GIFT Nifty Index belongs to India. It is traded on the NSE International Exchange (NSE IX) in GIFT City, Gujarat, which is part of India’s Special Economic Zone (SEZ) aimed at attracting international financial activities.`,
    },
    {
        title: "What is GIFT Nifty and SGX Nifty?",
        content: `GIFT Nifty is the rebranded version of SGX Nifty. SGX Nifty was a futures contract based on the Nifty 50 index traded on the Singapore Exchange. With the transition to GIFT Nifty, the base of these contracts moved to the NSE International Exchange in GIFT City, India, and the contracts are now traded in US dollars.`,
    },
    {
        title: "What are GIFT Nifty Timings?",
        content: `GIFT Nifty operates in two trading sessions. First Session starts from 6:30 AM IST and continues till to 3:40 PM IST and the second session starts at 4:35 PM IST and continues to 2:45 AM IST the next day. These timings provide nearly 21 hours of trading each day, allowing for extended market coverage and overlap with global financial markets.`,
    },
];

const config = {
    animate: true,
    openOnload: 0,
};

const splitNumberToDigits = (number) => {
    if (!number) return [];
    // Convert number to string and split into an array of characters
    return number.toLocaleString('en-IN').split('');
};

const HomeScreenMobile = () => {
    const navigate = useNavigate();
    const [rateData, setRateData] = useState(null);
    const [candlestickData, setCandlestickData] = useState([]);
    const [blogs, setBlogs] = useState([]);
    const [containerWidth, setContainerWidth] = useState(window.innerWidth - 40);
    const [tags, setTags] = useState([]); // State for storing tags
    const [news, setNews] = useState([]);
    const [isExpanded, setIsExpanded] = useState(false);
    const [chartType, setChartType] = useState('candlestick');
    const [lineChartData, setLineChartData] = useState([]);
    const [lineChartDataMinute, setLineChartDataMinute] = useState([]);
    const [isGiftNiftyOpen, setGiftNiftyOpen] = useState(false);

    useEffect(() => {
        // Update width on window resize
        const handleResize = () => {
            setContainerWidth(window.innerWidth - 40); // Adjust for padding
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleGiftNiftyToggle = () => {
        setGiftNiftyOpen(prev => !prev); // Toggle dropdown
    };

    const handleGiftNiftyMouseEnter = () => {
        setGiftNiftyOpen(true); // Open dropdown on hover
    };

    const handleGiftNiftyMouseLeave = () => {
        setGiftNiftyOpen(false); // Close dropdown on mouse leave
    };

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };


    useEffect(() => {
        // Fetch tags
        fetch('https://insider.giftnifty.com.in/wp-json/wp/v2/tags')
            .then(response => response.json())
            .then(tagData => {
                const formattedTags = tagData.map(tag => ({
                    id: tag.id,
                    name: tag.name
                }));
                setTags(formattedTags);
            }).catch((error) => {

            });

        // Fetch blog posts
        fetch('https://insider.giftnifty.com.in/wp-json/wp/v2/posts')
            .then(response => response.json())
            .then(data => {
                const formattedBlogs = data
                    .filter(blog => blog.categories.includes(2)) // Filter blogs with category ID 2
                    .map(blog => ({
                        id: blog.id,
                        title: blog.title.rendered,
                        description: blog.excerpt.rendered,
                        thumbnail: blog.uagb_featured_image_src["2048x2048"][0],
                        createdAt: blog.date,
                        tag: blog.tags[0], // First tag ID (we will map this to a tag name)
                        link: blog.link, // Blog link for opening in a new tab
                    }));
                setBlogs(formattedBlogs);
                const formattedBlogs2 = data
                    .filter(blog => blog.categories.includes(1)) // Filter blogs with category ID 2
                    .map(blog => ({
                        id: blog.id,
                        title: blog.title.rendered,
                        description: blog.excerpt.rendered,
                        thumbnail: blog.uagb_featured_image_src["2048x2048"][0],
                        createdAt: blog.date,
                        tag: blog.tags[0], // First tag ID (we will map this to a tag name)
                        link: blog.link, // Blog link for opening in a new tab
                    }));
                setNews(formattedBlogs2);
            }).catch((error) => {

            });
    }, []);


    // Helper function to get tag name by ID
    const getTagName = (tagId) => {
        const tag = tags.find(t => t.id === tagId);
        return tag ? tag.name : 'No Tag';
    };

    const otherBlogs = blogs;

    // Function to fetch data from the API
    const fetchCandlestickData = async () => {
        try {
            const response = await fetch("https://giftnifty.com.in/api/candles");
            const data = await response.json();

            // Format data for CandlestickChart
            const formattedData = data.map((candle) => {
                const dateObj = new Date(candle.timestamp);

                // Format date as yyyy-mm-dd hh:mm:ss
                const formattedDate = dateObj.getFullYear() + "-" +
                    String(dateObj.getMonth() + 1).padStart(2, '0') + "-" +
                    String(dateObj.getDate()).padStart(2, '0');

                return {
                    date: formattedDate, // use the new format
                    open: candle.open,
                    high: candle.high,
                    low: candle.low,
                    close: candle.close,
                };
            });

            setCandlestickData(formattedData);
            const lineChartD = formattedData.map((candle) => ({
                date: candle.date, // Ensure this is the correct formatted date
                closingPrice: candle.close,
            }));
            setLineChartData(lineChartD);
        } catch (error) {
            console.error("Error fetching candlestick data:", error);
        }
    };

    const fetchCandlestickDataMinute = async () => {
        try {
            const response = await fetch("https://giftnifty.com.in/api/candles-minute");
            const data = await response.json();

            // Format data for CandlestickChart
            const formattedData = data.map((candle) => {
                const dateObj = new Date(candle.timestamp);

                // Format date as yyyy-mm-dd hh:mm:ss
                const formattedDate =
                    String(dateObj.getHours()).padStart(2, '0') + ":" +
                    String(dateObj.getMinutes()).padStart(2, '0');

                return {
                    date: formattedDate, // use the new format
                    open: candle.open,
                    high: candle.high,
                    low: candle.low,
                    close: candle.close,
                };
            });
            const lineChartD = formattedData.map((candle) => ({
                date: candle.date, // Ensure this is the correct formatted date
                closingPrice: candle.close,
            }));
            setLineChartDataMinute(lineChartD);
        } catch (error) {
            console.error("Error fetching candlestick data:", error);
        }
    };

    // useEffect to fetch data every 15 minutes
    useEffect(() => {
        // Fetch data initially
        fetchCandlestickData();
        fetchCandlestickDataMinute();

        // Set interval to refresh data every 15 minutes (900,000 ms)
        const intervalId = setInterval(fetchCandlestickDataMinute, 60000);

        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        const fetchRates = async () => {
            try {
                const response = await fetch(`https://giftnifty.com.in/api/tick`);
                const data = await response.json();
                setRateData(data);
            } catch (error) {
                console.error("Error fetching rates:", error);
            }
        };

        // Fetch data initially
        fetchRates();

        // Set an interval to refresh the data every 5 seconds
        const interval = setInterval(() => {
            fetchRates();
        }, 2000);

        // Cleanup the interval when the component is unmounted
        return () => clearInterval(interval);
    }, []);


    const renderDigitsWithRoll = (number) => {
        const digits = splitNumberToDigits(number);
        return digits.map((digit, index) => (
            <span key={`${digit}-${index}`} className="digit-roll">
                {digit}
            </span>
        ));
    };

    const handleClick = () => {
        navigate("/blogs");
    };

    const handleChartTypeChange = (e) => {
        setChartType(e.target.value);
    };

    const closingPrices = lineChartData.map(data => data.closingPrice);
    const minPrice = Math.min(...closingPrices);
    const maxPrice = Math.max(...closingPrices);

    const yAxisDomain = [minPrice - 50, maxPrice + 50]; // Set Y-axis domain


    const closingPrices2 = lineChartDataMinute.map(data => data.closingPrice);
    const minPrice2 = Math.min(...closingPrices2);
    const maxPrice2 = Math.max(...closingPrices2);

    const yAxisDomain2 = [minPrice2 - 50, maxPrice2 + 50]; // Set Y-axis domain
    const openBlogInNewTab = (url) => {
        window.open(url, '_blank', 'noopener,noreferrer'); // Opens the blog in a new tab
    };
    return (<div className="desktop">
        <div
            style={{
                height: '90px',
                backgroundColor: '#181A23',
                display: 'flex',
                flexDirection: 'column', // Stack items vertically
                alignItems: 'center', // Center items horizontally
                justifyContent: 'center', // Center items vertically
                width: '100%',
                position: 'sticky',
                top: 0,
                zIndex: 1000,
                padding: '10px 20px', // Add padding for left/right
            }}
        >
            <img
                className="logo-white"
                alt="Logo white"
                src={require("../assets/logo-white.png")}
                style={{ cursor: 'pointer', width: '150px', height: 'auto' }} // Adjust logo size
                onClick={() => {
                    navigate("/mobile");
                }}
            />
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-around', // Space out navigation items
                    flexWrap: 'wrap', // Allow wrapping
                    gap: '15px', // Use gap for spacing between elements
                    marginTop: '10px', // Add some margin above the nav items
                    width: '100%', // Full width for nav items
                }}
            >
                <p
                    onClick={() => {
                        navigate("/mobile/blogs");
                    }}
                    style={{
                        cursor: "pointer",
                        fontFamily: 'Poppins',
                        fontSize: '16px',
                        fontWeight: '600',
                        color: '#DFE0ED',
                        margin: '0', // Remove margin
                    }}
                >
                    BLOGS
                </p>
                <p
                    onClick={() => {
                        navigate("/mobile/news");
                    }}
                    style={{
                        cursor: "pointer",
                        fontFamily: 'Poppins',
                        fontSize: '16px',
                        fontWeight: '600',
                        color: '#DFE0ED',
                        margin: '0', // Remove margin
                    }}
                >
                    NEWS
                </p>
                <div
                    onMouseEnter={handleGiftNiftyMouseEnter}
                    onMouseLeave={handleGiftNiftyMouseLeave}
                    style={{ position: 'relative' }}
                >
                    <p
                        style={{
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontWeight: '600',
                            color: '#DFE0ED',
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            margin: '0', // Remove margin
                        }}
                    >
                        GIFT NIFTY <FaChevronDown style={{ marginLeft: '10px' }} />
                    </p>
                    {isGiftNiftyOpen && (
                        <div
                            style={{
                                position: 'absolute',
                                backgroundColor: '#181A23',
                                borderRadius: '5px',
                                padding: '10px',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                                zIndex: 100,
                                width: '200px', // Adjust width for mobile
                            }}
                        >
                            {[
                                { text: "What is GIFT NIFTY?", link: "https://insider.giftnifty.com.in/blog/what-is-gift-nifty/" },
                                { text: "GIFT NIFTY VS SGX NIFTY", link: "https://insider.giftnifty.com.in/blog/gift-nifty-vs-sgx-nifty/" },
                                { text: "GIFT NIFTY VS NIFTY 50", link: "https://insider.giftnifty.com.in/blog/gift-nifty-vs-nifty-50/" },
                                { text: "GIFT NIFTY Timing", link: "https://insider.giftnifty.com.in/blog/gift-nifty-timings/" },
                            ].map((item, index) => (
                                <a
                                    key={index}
                                    href={item.link}
                                    target="_blank"
                                    style={{
                                        fontFamily: 'Poppins',
                                        fontSize: '16px',
                                        fontWeight: '600',
                                        color: '#DFE0ED',
                                        margin: '10px 0', // Adjust margin for mobile
                                        cursor: 'pointer',
                                        display: 'block', // Ensure each link is displayed in a block
                                    }}
                                >
                                    {item.text}
                                </a>
                            ))}
                        </div>
                    )}
                </div>

                <FaSearch
                    onClick={() => {
                        navigate("/mobile/search");
                    }}
                    size={20}
                    style={{ color: '#8C52FF' }} // Removed margin for icon
                />
            </div>
        </div>
        <div class="scrolling-text-container"
            onClick={() => navigate(`/mobile/disclaimer`)}>
            <p class="scrolling-text" style={{ width: '100%', color: '#8C89B4', fontFamily: 'Poppins', }}>This is an independent website and is not affiliated with any brands like SGX, NSE, NSEIX, IFSC, GIFT City, Nifty, Nifty 50, Nifty 100, or similar. <span style={{ color: '#8D52FF' }}>Know More...</span></p>
        </div>
        <div style={{
            display: 'flex', flexDirection: 'column',
            alignContent: 'center',
            alignItems: 'center',
        }}>
            <div className="main-axis" style={{
                display: 'flex',
                width: `${containerWidth}px`, // Set the width based on the state
                flexDirection: 'column',
                flexWrap: 'nowrap',
                alignContent: 'center',
                alignItems: 'center',
                justifyContent: 'space-between', // Space out items evenly
            }}>
                {rateData && (
                    <>
                        <div className="stat-item" style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', margin: '10px 0' }}>
                            <p className="stat-item-title" style={{ margin: '0', fontWeight: '600', color: '#FFFFFF' }}>Last Trade</p>
                            <p className="stat-item-text" style={{ margin: '0', color: '#DFE0ED' }}>
                                {renderDigitsWithRoll(rateData[0].last_price)}
                            </p>
                        </div>

                        <div className="stat-item" style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', margin: '10px 0' }}>
                            <p className="stat-item-title" style={{ margin: '0', fontWeight: '600', color: '#FFFFFF' }}>Change</p>
                            <p className="stat-item-text" style={{ margin: '0', color: '#DFE0ED' }}>
                                {renderDigitsWithRoll(rateData[0].last_price * rateData[0].change / 100)}
                            </p>
                        </div>

                        <div className="stat-item" style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', margin: '10px 0' }}>
                            <p className="stat-item-title" style={{ margin: '0', fontWeight: '600', color: '#FFFFFF' }}>Change in %</p>
                            <p
                                className="stat-item-text"
                                style={{
                                    margin: '0',
                                    color: rateData[0].change < 0 ? '#FF0000' : '#45B37C',
                                }}
                            >
                                {renderDigitsWithRoll(rateData[0].change)}%
                            </p>
                        </div>

                        <div className="stat-item" style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', margin: '10px 0' }}>
                            <p className="stat-item-title" style={{ margin: '0', fontWeight: '600', color: '#FFFFFF' }}>High</p>
                            <p className="stat-item-text" style={{ margin: '0', color: '#DFE0ED' }}>
                                {renderDigitsWithRoll(rateData[0].ohlc.high)}
                            </p>
                        </div>

                        <div className="stat-item" style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', margin: '10px 0' }}>
                            <p className="stat-item-title" style={{ margin: '0', fontWeight: '600', color: '#FFFFFF' }}>Low</p>
                            <p className="stat-item-text" style={{ margin: '0', color: '#DFE0ED' }}>
                                {renderDigitsWithRoll(rateData[0].ohlc.low)}
                            </p>
                        </div>

                        <div className="stat-item" style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', margin: '10px 0' }}>
                            <p className="stat-item-title" style={{ margin: '0', fontWeight: '600', color: '#FFFFFF' }}>Open</p>
                            <p className="stat-item-text" style={{ margin: '0', color: '#DFE0ED' }}>
                                {renderDigitsWithRoll(rateData[0].ohlc.open)}
                            </p>
                        </div>
                    </>
                )}
            </div>
            <div style={{ display: 'flex', margin: '10px', justifyContent: 'center', alignItems: 'center', }}>
                <div class="color-changing-circle" style={{ marginRight: '10px', }}></div>
                <CurrentTime />
            </div>
            <div className="main-axis" style={{
                flexDirection: 'column',
                backgroundColor: '#181a23',
                borderRadius: '20px',
                padding: '20px',
                display: 'flex',
                width: `${containerWidth}px`, // Set the width based on the state
                flexDirection: 'column',
                flexWrap: 'nowrap',

            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexWrap: 'wrap', // Allow wrapping for mobile
                }}>
                    <h2 className="content-title" style={{ marginLeft: '15px', fontSize: '18px' }}>GIFT NIFTY Live Chart</h2>

                </div>
                <div style={{ marginTop: '10px', display: 'flex', flexDirection: 'row', }}>
                    <label style={{ flex: 1, fontSize: '16px', fontWeight: '600', fontFamily: 'Poppins', color: '#8C89B4', display: 'flex', alignItems: 'center' }} className="radio-label">
                        <input
                            type="radio"
                            value="candlestick"
                            checked={chartType === 'candlestick'}
                            onChange={handleChartTypeChange}
                            className="radio-input"
                            style={{
                                marginRight: '8px',
                                accentColor: chartType === 'candlestick' ? '#8C52FF' : '#DFE0ED', // Change accent color when selected
                                width: '25px', // Custom width for the radio button
                                height: '20px' // Custom height for the radio button
                            }}
                        />
                        Intraday (24 hrs)
                    </label>
                    <label style={{ flex: 1, fontSize: '16px', fontWeight: '600', fontFamily: 'Poppins', color: '#8C89B4', display: 'flex', alignItems: 'center' }} className="radio-label">
                        <input
                            type="radio"
                            value="line"
                            checked={chartType === 'line'}
                            onChange={handleChartTypeChange}
                            className="radio-input"
                            style={{
                                marginRight: '8px',
                                accentColor: chartType === 'line' ? '#8C52FF' : '#DFE0ED', // Change accent color when selected
                                width: '30px', // Custom width for the radio button
                                height: '20px' // Custom height for the radio button
                            }}
                        />
                        Historical (6 months)
                    </label>
                </div>
                <div style={{
                    alignSelf: 'center',
                    fontSize: '14px',
                    height: '300px', // Adjust height for better mobile viewing
                    width: '100%', // Make the chart responsive
                    marginTop: '20px', // Add margin for spacing
                }}>
                    <ResponsiveContainer width="100%" height="100%">
                        {chartType === 'candlestick' ? (
                            <LineChart data={lineChartDataMinute}>
                                <CartesianGrid strokeDasharray="3 3" stroke="#333333" />
                                <YAxis domain={yAxisDomain2} orientation="right" />
                                <XAxis
                                    dataKey="date"
                                    interval={20}
                                />
                                <Tooltip />
                                <Line dot={false} type="monotone" dataKey="closingPrice" stroke="#8884d8" />
                            </LineChart>
                        ) : (
                            <LineChart data={lineChartData}>
                                <CartesianGrid strokeDasharray="3 3" stroke="#333333" />
                                <YAxis domain={yAxisDomain2} orientation="right" />
                                <XAxis
                                    dataKey="date"
                                    interval={30}
                                />
                                <Tooltip />
                                <Line dot={false} type="monotone" dataKey="closingPrice" stroke="#8884d8" />
                            </LineChart>
                        )}
                    </ResponsiveContainer>
                </div>
            </div>
            <div className="main-axis" style={{
                flexDirection: 'column',
                backgroundColor: '#181a23',
                borderRadius: '20px',
                padding: '20px',
                display: 'flex',
                width: `${containerWidth}px`, // Set the width based on the state
                flexDirection: 'column',
                flexWrap: 'nowrap',
            }}>
                <h2 className="content-title" style={{ marginLeft: '15px', fontSize: '18px' }}>Latest Blogs</h2>
                {otherBlogs.slice(0, 3).map(blog => (
                    <div key={blog.id} className="blog-item" onClick={() => openBlogInNewTab(blog.link)} style={{ cursor: 'pointer' }}>
                        <img className="blog-item-image" alt="Blog" src={blog.thumbnail} style={{ width: '100%', height: 'auto', borderRadius: '10px' }} />
                        <div className="blog-item-details">
                            <div className="blog-item-details-internal">
                                <div className="blog-item-category-box">
                                    <p className="blog-item-category">{getTagName(blog.tag)}</p>
                                </div>
                                <p className="blog-item-title">{blog.title}</p>
                                <div className="blog-item-description"
                                    dangerouslySetInnerHTML={{ __html: blog.description }}
                                    style={{ lineHeight: 'normal', letterSpacing: 'normal' }}
                                />
                            </div>
                            <div className="blog-item-date">{new Date(blog.createdAt).toDateString()}</div>
                        </div>
                    </div>
                ))}
                <div
                    className="view-all-button"
                    onClick={() => {
                        navigate("/mobile/blogs");
                    }}
                    style={{
                        cursor: "pointer",
                        padding: '10px 20px',
                        borderRadius: '5px',
                        backgroundColor: '#8C52FF',
                        display: 'flex', // Use flexbox for centering
                        justifyContent: 'center', // Center horizontally
                        alignItems: 'center', // Center vertically
                        height: '50px' // Set a height for better vertical centering
                    }}
                >
                    <p
                        className="view-all-button-text"
                        style={{
                            color: '#FFFFFF',
                            fontSize: '16px',
                            fontWeight: '600',
                            margin: 0 // Remove default margin
                        }}
                    >
                        View All
                    </p>
                </div>
            </div>
            <div className="main-axis" style={{
                flexDirection: 'column',
                backgroundColor: '#181a23',
                borderRadius: '20px',
                padding: '20px',
                display: 'flex',
                width: `${containerWidth}px`, // Set the width based on the state
                flexDirection: 'column',
                flexWrap: 'nowrap',
            }}>
                <div style={{
                    flexDirection: 'row',
                    display: 'flex',
                    marginBottom: "10px",
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexWrap: 'wrap', // Allow wrapping on smaller screens
                }}>
                    <h2 className="content-title" style={{ marginLeft: '15px', fontSize: '18px' }}>About GIFT Nifty</h2>
                    <div
                        style={{
                            cursor: "pointer",
                            height: '20px',
                            width: '20px',
                            padding: '0px',
                            margin: '0px',
                            marginTop: '22px',
                            marginRight: '15px', // Adjusted for smaller screens
                            transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)",
                            transition: "transform 0.3s ease",
                            transformOrigin: 'center',
                            display: 'flex',
                            justifyContent: 'center',
                            color: '#DFE0ED',
                            alignItems: 'center',
                        }}
                        onClick={toggleExpand}
                    >
                        ▼
                    </div>
                </div>

                {/* Collapsible Content */}
                <div
                    className="collapsible-content"
                    style={{
                        maxHeight: isExpanded ? "6000px" : "180px", // Adjust the maxHeight according to your content
                        overflow: "hidden",
                        transition: "max-height 0.5s ease-in-out",
                    }}
                >
                    <p className="about-text" style={{ marginLeft: '0px', lineHeight: 'normal', fontSize: '18px', width: '100%' }}>
                        <span className="span">
                            In recent years, India has emerged as a prominent player in the global financial landscape. A significant milestone in this journey is the transition from SGX Nifty to GIFT Nifty. This shift represents a strategic move to strengthen India’s position as a financial hub and offer enhanced trading opportunities. In this blog post, we will delve into what GIFT Nifty is, its benefits, and how it works, along with key details like its timings, types of contracts, and trading mechanisms.
                            <br /><br />
                        </span>

                        <h3 className="about-sub-title">
                            What is GIFT Nifty? <br /><br />
                        </h3>

                        <span className="about-text-bold">GIFT Nifty</span>
                        <span className="span">
                            {" "}is the rebranded version of SGX Nifty, marking a pivotal shift from the Singapore Exchange (SGX) to the NSE International Exchange (NSE IX) in GIFT City, Gandhinagar, Gujarat. The transition took place on July 3, 2023, following regulatory approvals from the International Financial Services Center Authority (IFSCA) and the Monetary Authority of Singapore (MAS). <br /><br />
                        </span>

                        <span className="about-text-bold">GIFT Nifty Full Form: </span>
                        <span className="span">Gujarat International Finance Tec-City Nifty. <br /><br /></span>

                        <span className="about-text-bold">GIFT Nifty Means:</span>
                        <span className="span">It represents a benchmark index that tracks the performance of the top 50 companies listed on the NSE (National Stock Exchange) in India. <br /><br /></span>

                        <h3 className="about-sub-title">Why SGX Nifty changed to GIFT Nifty?<br /><br /></h3>

                        <span className="span">
                            The SGX Nifty, a futures contract based on the Nifty 50 index, was previously traded on the Singapore Exchange. It allowed international investors to gain exposure to the Indian market indirectly.<br /><br />However, the trading volume and liquidity benefits were largely favoring Singapore. To bring these benefits back to India and enhance control over capital markets, the SGX Nifty was rebranded as GIFT Nifty and shifted to GIFT City.<br /><br />This move not only centralizes trading within India but also integrates it into the global financial system, providing tax incentives and operational benefits.
                            <br /><br />
                        </span>

                        <h3 className="about-sub-title">GIFT Nifty Timings<br /><br /></h3>
                        <span className="span">
                            GIFT Nifty Timings are designed to offer extended trading hours, aligning with global market schedules. Here’s a breakdown: <br /><br />

                            <table style={{ borderCollapse: 'collapse', width: '100%', textAlign: 'left', marginBottom: '15px', color: '#fff' }}>
                                <thead>
                                    <tr>
                                        <th style={{ padding: '8px' }}>Session</th>
                                        <th style={{ padding: '8px' }}>Start Time (IST)</th>
                                        <th style={{ padding: '8px' }}>End Time (IST)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ padding: '8px' }}>First Session</td>
                                        <td style={{ padding: '8px' }}>6:30 AM</td>
                                        <td style={{ padding: '8px' }}>3:40 PM</td>
                                    </tr>
                                    <tr>
                                        <td style={{ padding: '8px' }}>Second Session</td>
                                        <td style={{ padding: '8px' }}>4:35 PM</td>
                                        <td style={{ padding: '8px' }}>2:45 AM (Next Day)</td>
                                    </tr>
                                </tbody>
                            </table>

                            These extended hours, totaling nearly 21 hours, ensure that GIFT Nifty overlaps with major financial markets across the US, Europe, and Asia.
                        </span><br /><br />
                        <h3 className="about-sub-title">Benefits of Trading GIFT Nifty<br /><br /></h3>
                        <span className="span">
                            <span className="about-text-bold">1. Extended Trading Hours:</span> GIFT Nifty offers nearly 21 hours of trading compared to the 16 hours of SGX Nifty. This extended timeframe allows traders to react to global market developments swiftly. <br /><br />
                            <span className="about-text-bold">2. Single Liquidity Pool:</span> By consolidating trading activities into one unified pool, GIFT Nifty enhances market efficiency, improves price discovery, and reduces trading costs. <br /><br />
                            <span className="about-text-bold">3. High Level of Certainty:</span> Operating under the regulatory framework of IFSCA, GIFT Nifty provides a robust and secure trading environment. Adherence to stringent guidelines reduces the risk of market manipulation and fraudulent activities. <br /><br />
                            <span className="about-text-bold">4. Tax Exemptions:</span> Trading within the Special Economic Zone (SEZ) of GIFT City offers exemptions from Securities Transaction Tax (STT), Commodity Transaction Tax (CTT), Dividend Distribution Tax (DDT), and capital gains tax. <br /><br />
                        </span>

                        <h3 className="about-sub-title">Different Types of GIFT Nifty Contracts<br /><br /></h3>
                        <span className="span">
                            GIFT Nifty offers various contracts catering to different sectors: <br /><br />

                            <table style={{ borderCollapse: 'collapse', width: '100%', textAlign: 'left', marginBottom: '15px', color: '#fff' }}>
                                <thead>
                                    <tr>
                                        <th style={{ padding: '8px' }}>Contract</th>
                                        <th style={{ padding: '8px' }}>Tracks</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ padding: '8px' }}>GIFT Nifty 50</td>
                                        <td style={{ padding: '8px' }}>Nifty 50 Index (top 50 NSE companies)</td>
                                    </tr>
                                    <tr>
                                        <td style={{ padding: '8px' }}>GIFT Nifty Bank</td>
                                        <td style={{ padding: '8px' }}>Nifty Bank Index (top 12 banks in India)</td>
                                    </tr>
                                    <tr>
                                        <td style={{ padding: '8px' }}>GIFT Nifty Financial Services</td>
                                        <td style={{ padding: '8px' }}>Nifty Financial Services Index (top 25 financial services companies)</td>
                                    </tr>
                                    <tr>
                                        <td style={{ padding: '8px' }}>GIFT Nifty IT</td>
                                        <td style={{ padding: '8px' }}>Nifty IT Index (top 25 IT companies in India)</td>
                                    </tr>
                                </tbody>
                            </table>
                        </span>


                        <h3 className="about-sub-title">How to Trade in GIFT Nifty<br /><br /></h3>
                        <span className="span">
                            <span className="about-text-bold">1. Set Up Your Trading Account:</span> Open an account with a broker registered on NSE IX. <br /><br />
                            <span className="about-text-bold">2. Fulfill KYC Obligations:</span> Complete the Know Your Customer (KYC) process by submitting necessary identity and address proofs. <br /><br />
                            <span className="about-text-bold">3. Deposit Funds:</span> Deposit money into your trading account. Note that trading can be conducted in currencies other than the Indian rupee. <br /><br />
                            <span className="about-text-bold">4. Understand Market Dynamics:</span> Familiarize yourself with the factors affecting GIFT Nifty and make informed investment decisions. <br /><br />
                        </span>

                        <h3 className="about-sub-title">GIFT Nifty VS SGX Nifty<br /><br /></h3>
                        <span className="span">
                            Here’s a comparison between SGX Nifty and GIFT Nifty: <br /><br />

                            <table style={{ borderCollapse: 'collapse', width: '100%', textAlign: 'left', marginBottom: '15px', color: '#fff' }}>
                                <thead>
                                    <tr>
                                        <th style={{ padding: '8px' }}>Aspect</th>
                                        <th style={{ padding: '8px' }}>SGX Nifty</th>
                                        <th style={{ padding: '8px' }}>GIFT Nifty</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ padding: '8px' }}>Reading Location</td>
                                        <td style={{ padding: '8px' }}>Singapore Exchange</td>
                                        <td style={{ padding: '8px' }}>NSE International Exchange, GIFT City, India</td>
                                    </tr>
                                    <tr>
                                        <td style={{ padding: '8px' }}>Marketing Time</td>
                                        <td style={{ padding: '8px' }}>16 hours a day</td>
                                        <td style={{ padding: '8px' }}>21 hours a day</td>
                                    </tr>
                                    <tr>
                                        <td style={{ padding: '8px' }}>Regulatory Environment</td>
                                        <td style={{ padding: '8px' }}>Singapore Exchange Regulation (SGX RegCo)</td>
                                        <td style={{ padding: '8px' }}>SEBI (Securities and Exchange Board of India)</td>
                                    </tr>
                                </tbody>
                            </table>
                        </span>


                        <hr style={{ border: '1px solid #121415', margin: '20px 0' }} />
                        <span className="span">
                            The introduction of <span className="about-text-bold">GIFT Nifty</span> represents a significant advancement in India’s financial markets, bringing substantial trading volume and liquidity back to the country. By offering extended trading hours and a robust regulatory framework, GIFT Nifty not only enhances market accessibility but also positions India as a key player in the global financial arena. For the latest updates and live prices of GIFT Nifty, visit giftnifty.com.in.
                        </span>
                    </p>
                </div>
            </div>
            <div className="main-axis" style={{
                flexDirection: 'column',
                backgroundColor: '#181a23',
                borderRadius: '20px',
                padding: '20px',
                display: 'flex',
                width: `${containerWidth}px`, // Set the width based on the state
                flexDirection: 'column',
                flexWrap: 'nowrap',
            }}>
                <h2 className="content-title" style={{ marginLeft: '15px', fontSize: '18px' }}>Frequently Asked Questions (FAQs)</h2>
                <div style={{
                    paddingBottom: '30px', fontFamily: "Poppins",
                    fontSize: '16px',
                    width: '100%',
                    lineHeight: 'normal',
                    color: '#DFE0ED'
                }}>
                    {data.map((faq, index) => (
                        <FAQAccordion key={index} faq={faq} />
                    ))}
                </div>
            </div>
            {/* <img className="main-axis" style={{
                flexDirection: 'column',
                borderRadius: '20px',
                display: 'flex',
                width: `${containerWidth}px`, // Set the width based on the state
                flexDirection: 'column',
                flexWrap: 'nowrap',
            }} alt="Logo white" src={require("../assets/ad.png")} /> */}
            <div className="main-axis" style={{
                flexDirection: 'column',
                backgroundColor: '#181a23',
                borderRadius: '20px',
                padding: '20px',
                display: 'flex',
                width: `${containerWidth}px`, // Set the width based on the state
                flexDirection: 'column',
                flexWrap: 'nowrap',
            }}>
                <h2 className="content-title" style={{ marginLeft: '15px', fontSize: '18px' }}>Quick Market View</h2>
                <div className="overview-item-table-header" style={{ padding: '0px' }}>
                    <div className="overview-item-column">
                        <div className="overview-item-title">Name</div>
                    </div>
                    <div className="overview-item-column">
                        <div className="overview-item-title">Price</div>
                    </div>
                    <div className="overview-item-column">
                        <div className="overview-item-title">Chg</div>
                    </div>
                    <div className="overview-item-column">
                        <div className="overview-item-title">Chg %</div>
                    </div>
                </div>
                <div className="overview-item-list" style={{ padding: '0px' }}>
                    <div className="overview-item-row">
                        <div className="overview-item">
                            <div className="overview-item-text">IRCTC</div>
                        </div>
                        <div className="overview-item">
                            <div className="overview-item-text">{rateData ? rateData.find((element) => element.instrument_token == 3484417).last_price.toLocaleString('en-IN').split('') : ''}</div>
                        </div>
                        <div className="overview-item">
                            <div className="overview-item-text">{rateData ? (rateData.find((element) => element.instrument_token == 3484417).last_price * rateData.find((element) => element.instrument_token == 3484417).change / 100).toFixed(3) : ''}</div>
                        </div>
                        <div className="overview-item">
                            <div className="overview-item-text" style={{ color: rateData && rateData.find((element) => element.instrument_token == 3484417).change < 0 ? '#FF0000' : '#45B37C' }}>{rateData ? rateData.find((element) => element.instrument_token == 3484417).change.toFixed(3) : ''}%</div>
                        </div>
                    </div>
                    <div className="overview-item-row">
                        <div className="overview-item">
                            <div className="overview-item-text">TCS</div>
                        </div>
                        <div className="overview-item">
                            <div className="overview-item-text">{rateData ? rateData.find((element) => element.instrument_token == 2953217).last_price.toLocaleString('en-IN').split('') : ''}</div>
                        </div>
                        <div className="overview-item">
                            <div className="overview-item-text">{rateData ? (rateData.find((element) => element.instrument_token == 2953217).last_price * rateData.find((element) => element.instrument_token == 2953217).change / 100).toFixed(3) : ''}</div>
                        </div>
                        <div className="overview-item">
                            <div className="overview-item-text" style={{ color: rateData && rateData.find((element) => element.instrument_token == 2953217).change < 0 ? '#FF0000' : '#45B37C' }}>{rateData ? rateData.find((element) => element.instrument_token == 2953217).change.toFixed(3) : ''}%</div>
                        </div>
                    </div>
                    <div className="overview-item-row">
                        <div className="overview-item">
                            <div className="overview-item-text">COLPAL</div>
                        </div>
                        <div className="overview-item">
                            <div className="overview-item-text">{rateData ? rateData.find((element) => element.instrument_token == 3876097).last_price.toLocaleString('en-IN').split('') : ''}</div>
                        </div>
                        <div className="overview-item">
                            <div className="overview-item-text">{rateData ? (rateData.find((element) => element.instrument_token == 3876097).last_price * rateData.find((element) => element.instrument_token == 3876097).change / 100).toFixed(3) : ''}</div>
                        </div>
                        <div className="overview-item">
                            <div className="overview-item-text" style={{ color: rateData && rateData.find((element) => element.instrument_token == 3876097).change < 0 ? '#FF0000' : '#45B37C' }}>{rateData ? rateData.find((element) => element.instrument_token == 3876097).change.toFixed(3) : ''}%</div>
                        </div>
                    </div>
                    <div className="overview-item-row">
                        <div className="overview-item">
                            <div className="overview-item-text">GILLETTE</div>
                        </div>
                        <div className="overview-item">
                            <div className="overview-item-text">{rateData ? rateData.find((element) => element.instrument_token == 403457).last_price.toLocaleString('en-IN').split('') : ''}</div>
                        </div>
                        <div className="overview-item">
                            <div className="overview-item-text">{rateData ? (rateData.find((element) => element.instrument_token == 403457).last_price * rateData.find((element) => element.instrument_token == 403457).change / 100).toFixed(3) : ''}</div>
                        </div>
                        <div className="overview-item">
                            <div className="overview-item-text" style={{ color: rateData && rateData.find((element) => element.instrument_token == 403457).change < 0 ? '#FF0000' : '#45B37C' }}>{rateData ? rateData.find((element) => element.instrument_token == 403457).change.toFixed(3) : ''}%</div>
                        </div>
                    </div>
                    <div className="overview-item-row">
                        <div className="overview-item">
                            <div className="overview-item-text">LLOYDSENGG</div>
                        </div>
                        <div className="overview-item">
                            <div className="overview-item-text">{rateData ? rateData.find((element) => element.instrument_token == 4557057).last_price.toLocaleString('en-IN').split('') : ''}</div>
                        </div>
                        <div className="overview-item">
                            <div className="overview-item-text">{rateData ? (rateData.find((element) => element.instrument_token == 4557057).last_price * rateData.find((element) => element.instrument_token == 4557057).change / 100).toFixed(3) : ''}</div>
                        </div>
                        <div className="overview-item">
                            <div className="overview-item-text" style={{ color: rateData && rateData.find((element) => element.instrument_token == 4557057).change < 0 ? '#FF0000' : '#45B37C' }}>{rateData ? rateData.find((element) => element.instrument_token == 4557057).change.toFixed(3) : ''}%</div>
                        </div>
                    </div>
                    <div className="overview-item-row">
                        <div className="overview-item">
                            <div className="overview-item-text">PGHH</div>
                        </div>
                        <div className="overview-item">
                            <div className="overview-item-text">{rateData ? rateData.find((element) => element.instrument_token == 648961).last_price.toLocaleString('en-IN').split('') : ''}</div>
                        </div>
                        <div className="overview-item">
                            <div className="overview-item-text">{rateData ? (rateData.find((element) => element.instrument_token == 648961).last_price * rateData.find((element) => element.instrument_token == 648961).change / 100).toFixed(3) : ''}</div>
                        </div>
                        <div className="overview-item">
                            <div className="overview-item-text" style={{ color: rateData && rateData.find((element) => element.instrument_token == 648961).change < 0 ? '#FF0000' : '#45B37C' }}>{rateData ? rateData.find((element) => element.instrument_token == 648961).change.toFixed(3) : ''}%</div>
                        </div>
                    </div>
                    <div className="overview-item-row">
                        <div className="overview-item">
                            <div className="overview-item-text">COALINDIA</div>
                        </div>
                        <div className="overview-item">
                            <div className="overview-item-text">{rateData ? rateData.find((element) => element.instrument_token == 5215745).last_price.toLocaleString('en-IN').split('') : ''}</div>
                        </div>
                        <div className="overview-item">
                            <div className="overview-item-text">{rateData ? (rateData.find((element) => element.instrument_token == 5215745).last_price * rateData.find((element) => element.instrument_token == 5215745).change / 100).toFixed(3) : ''}</div>
                        </div>
                        <div className="overview-item">
                            <div className="overview-item-text" style={{ color: rateData && rateData.find((element) => element.instrument_token == 5215745).change < 0 ? '#FF0000' : '#45B37C' }}>{rateData ? rateData.find((element) => element.instrument_token == 5215745).change.toFixed(3) : ''}%</div>
                        </div>
                    </div>
                    <div className="overview-item-row">
                        <div className="overview-item">
                            <div className="overview-item-text">CASTROLIND</div>
                        </div>
                        <div className="overview-item">
                            <div className="overview-item-text">{rateData ? rateData.find((element) => element.instrument_token == 320001).last_price.toLocaleString('en-IN').split('') : ''}</div>
                        </div>
                        <div className="overview-item">
                            <div className="overview-item-text">{rateData ? (rateData.find((element) => element.instrument_token == 320001).last_price * rateData.find((element) => element.instrument_token == 320001).change / 100).toFixed(3) : ''}</div>
                        </div>
                        <div className="overview-item">
                            <div className="overview-item-text" style={{ color: rateData && rateData.find((element) => element.instrument_token == 320001).change < 0 ? '#FF0000' : '#45B37C' }}>{rateData ? rateData.find((element) => element.instrument_token == 320001).change.toFixed(3) : ''}%</div>
                        </div>
                    </div>
                    <div className="overview-item-row">
                        <div className="overview-item">
                            <div className="overview-item-text">GLAXO</div>
                        </div>
                        <div className="overview-item">
                            <div className="overview-item-text">{rateData ? rateData.find((element) => element.instrument_token == 295169).last_price.toLocaleString('en-IN').split('') : ''}</div>
                        </div>
                        <div className="overview-item">
                            <div className="overview-item-text">{rateData ? (rateData.find((element) => element.instrument_token == 295169).last_price * rateData.find((element) => element.instrument_token == 295169).change / 100).toFixed(3) : ''}</div>
                        </div>
                        <div className="overview-item">
                            <div className="overview-item-text" style={{ color: rateData && rateData.find((element) => element.instrument_token == 295169).change < 0 ? '#FF0000' : '#45B37C' }}>{rateData ? rateData.find((element) => element.instrument_token == 295169).change.toFixed(3) : ''}%</div>
                        </div>
                    </div>
                    <div className="overview-item-row">
                        <div className="overview-item">
                            <div className="overview-item-text">NESTLEIND</div>
                        </div>
                        <div className="overview-item">
                            <div className="overview-item-text">{rateData ? rateData.find((element) => element.instrument_token == 4598529).last_price.toLocaleString('en-IN').split('') : ''}</div>
                        </div>
                        <div className="overview-item">
                            <div className="overview-item-text">{rateData ? (rateData.find((element) => element.instrument_token == 4598529).last_price * rateData.find((element) => element.instrument_token == 4598529).change / 100).toFixed(3) : ''}</div>
                        </div>
                        <div className="overview-item">
                            <div className="overview-item-text" style={{ color: rateData && rateData.find((element) => element.instrument_token == 4598529).change < 0 ? '#FF0000' : '#45B37C' }}>{rateData ? rateData.find((element) => element.instrument_token == 4598529).change.toFixed(3) : ''}%</div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="main-axis" style={{
                flexDirection: 'column',
                backgroundColor: '#181a23',
                borderRadius: '20px',
                padding: '20px',
                display: 'flex',
                width: `${containerWidth}px`, // Set the width based on the state
                flexDirection: 'column',
                flexWrap: 'nowrap',
            }}>
                <h2 className="content-title" style={{ marginLeft: '15px', fontSize: '18px' }}>Latest News</h2>
                <div style={{
                    marginTop: '10px',
                    marginBottom: '20px',
                    flexDirection: 'row',
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 'inherit',
                    justifyContent: 'space-between'
                }}>{news.slice(0, 9).map((news) => {
                    return <div>
                        <div className="blog-item-details-internal" onClick={() => openBlogInNewTab(news.link)} style={{ cursor: "pointer" }}>
                            <div className="blog-item-category-box">
                                <p className="blog-item-category">{getTagName(news.tag)}</p>
                            </div>
                            <p className="blog-item-title">{news.title}</p>
                            <div className="blog-item-description"
                                dangerouslySetInnerHTML={{ __html: news.description }}
                                style={{ lineHeight: 'normal', letterSpacing: 'normal' }}
                            />
                        </div>
                        <div style={{ height: '20px' }} />
                    </div>
                })}
                </div>
                <div
                    className="view-all-button"
                    onClick={() => {
                        navigate("/mobile/news");
                    }}
                    style={{
                        cursor: "pointer",
                        padding: '10px 20px',
                        borderRadius: '5px',
                        backgroundColor: '#8C52FF',
                        display: 'flex', // Use flexbox for centering
                        justifyContent: 'center', // Center horizontally
                        alignItems: 'center', // Center vertically
                        height: '50px' // Set a height for better vertical centering
                    }}
                >
                    <p
                        className="view-all-button-text"
                        style={{
                            color: '#FFFFFF',
                            fontSize: '16px',
                            fontWeight: '600',
                            margin: 0 // Remove default margin
                        }}
                    >
                        View All
                    </p>
                </div>
            </div>
        </div>
        <div style={{ marginTop: '30px', backgroundColor: '#181A23', display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
            <div style={{ width: '100%', maxWidth: '1341px', marginTop: '30px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <img
                    className="logo-white"
                    alt="Logo white"
                    src={require("../assets/logo-white.png")}
                    style={{ cursor: 'pointer', width: '100%', maxWidth: '210px' }}
                    onClick={() => navigate("/mobile")}
                />
                <div style={{ marginTop: '30px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <p style={{
                        fontFamily: 'Poppins',
                        fontSize: '16px',
                        fontWeight: '600',
                        color: '#DFE0ED',
                        margin: '10px',
                        textTransform: 'uppercase',
                        cursor: "pointer"
                    }} onClick={() => navigate(`/mobile/about`)}>About</p>
                    <p style={{
                        fontFamily: 'Poppins',
                        fontSize: '16px',
                        fontWeight: '600',
                        color: '#DFE0ED',
                        margin: '10px',
                        textTransform: 'uppercase',
                        cursor: "pointer"
                    }} onClick={() => navigate(`/mobile/contact-us`)}>Contact Us</p>
                    <p style={{
                        fontFamily: 'Poppins',
                        fontSize: '16px',
                        fontWeight: '600',
                        color: '#DFE0ED',
                        margin: '10px',
                        textTransform: 'uppercase',
                        cursor: "pointer"
                    }} onClick={() => {
                        navigate(`/mobile/terms-and-conditions`);
                    }} >Terms and Conditions</p>
                    <p style={{
                        fontFamily: 'Poppins',
                        fontSize: '16px',
                        fontWeight: '600',
                        color: '#DFE0ED',
                        margin: '10px',
                        textTransform: 'uppercase',
                        cursor: "pointer"
                    }} onClick={() => {
                        navigate(`/mobile/privacy-policy`);
                    }} >Privacy Policy</p>
                    <p style={{
                        fontFamily: 'Poppins',
                        fontSize: '16px',
                        fontWeight: '600',
                        color: '#DFE0ED',
                        margin: '10px',
                        textTransform: 'uppercase',
                        cursor: "pointer"
                    }} onClick={() => navigate(`/mobile/disclaimer`)}>Disclaimer</p>
                    <p style={{
                        fontFamily: 'Poppins',
                        fontSize: '12px',
                        color: '#DFE0ED',
                        textAlign: 'center',
                        margin: '20px',
                        lineHeight: 'normal',
                    }}>This website is solely for stock market information purposes and is not affiliated with any official entities such as SGX, NSE, NSEIX, IFSC, GIFT City, or Nifty. It does not act as a financial advisor or influencer, and we do not offer trading or investment advice, tips,
                        recommendations, or strategies through our website, social media channels, or any other
                        medium.<br /> <br />All users and members of this website are subject to our Disclaimer, Disclosure, Privacy
                        Policy, and Terms & Conditions. By using this website, you acknowledge and agree to all of the above terms and conditions.
                    </p>
                    <p style={{
                        fontFamily: 'Poppins',
                        fontSize: '16px',
                        fontWeight: '600',
                        color: '#DFE0ED',
                        margin: '20px',
                        cursor: 'pointer'
                    }} onClick={() => navigate("/mobile")}>© 2024 GIFTNIFTY</p>
                </div>
            </div>
        </div>
    </div >);
}

export default HomeScreenMobile


// {chartType === 'candlestick' ? (
//     <CandlestickChart
//         data={candlestickData.map(candle => ({
//             date: candle.date, // Use your formatted date
//             open: candle.open,
//             high: candle.high,
//             low: candle.low,
//             close: candle.close,
//         }))}
//         id={"chart1"}
//         width={900}
//         height={600}
//         enableResetButton={false}
//         ColorPalette={{
//             background: 'transparent',
//             selectorLine: 'transparent',
//         }}
//         decimal={5}
//         scrollZoom={{
//             enable: true,
//             max: 100,
//         }}
//         // rangeSelector={{
//         //     enable: true,
//         //     height: 150,
//         //     initialRange: { type: "month", value: 1 },
//         // }}
//         responsiveBreakPoint={500}
//     />
// ) : <ResponsiveContainer width={900} height={600}>
//     <LineChart data={lineChartData}>
//         <YAxis domain={yAxisDomain} orientation="right" />
//         <XAxis dataKey="date" /> {/* Set orientation to right */}
//         <Tooltip />
//         <Line dot={false} type="monotone" dataKey="closingPrice" stroke="#8884d8" />
//     </LineChart>
// </ResponsiveContainer>}